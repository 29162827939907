/**
 * convert an entry's tags csv string into an array
 */
export const getTags = (tags: string | undefined): string[] =>
    (tags &&
        tags.trim() &&
        tags
            .split(",")
            .map((tag) => tag.trim())
            .filter(Boolean)) ||
    [];

/**
 * lower case tags
 */
export const normalizeTags = (tagsList: string[]) =>
    tagsList.map((tag) => tag.toLowerCase());
