import styled from "@emotion/styled";
import { StyledInput } from "../FormElementStyles";
import { ChangeEventHandler } from "react";
import { IconButton, Tooltip } from "@kaltura/ds-react-components";
import { Remove24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@mediaspace/shared/utils";

export interface SingleTextProps {
    htmlId: string;
    id: number;
    ariaLabelledby?: string;
    error?: boolean;
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    onRemove: (id: number) => void;
}

const StyledSingleText = styled.div(({ theme }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
    margin: theme.spacing(1, 0),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.kaltura.palette.tone3,
}));

function SingleText({htmlId, id, ariaLabelledby, error, value, onChange, onRemove}: SingleTextProps) {
    return (
        <StyledSingleText>
            <StyledInput id={htmlId} error={error} value={value} onChange={onChange} inputProps={{"aria-labelledby": ariaLabelledby}}/>
            <Tooltip title={translate("remove")}>
                <StyledIconButton variant={"borderless"} onClick={() => onRemove(id)}><Remove24Icon /></StyledIconButton>
            </Tooltip>
        </StyledSingleText>
    );
}

export default SingleText;
