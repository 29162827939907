import React from "react";
import { getDsLayoutComponentRenderer } from "@mediaspace/features/layout";

const HomePage = React.lazy(() => import("@mediaspace/features/home-page"));
const UpcomingSessions = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/upcoming-sessions/UpcomingSessions"
        )
);
const News = React.lazy(
    () => import("@mediaspace/features/home-page/components/news/News")
);
const SinglePlaylist = React.lazy(
    () =>
        import("@mediaspace/shared/ui/single-playlist/SinglePlaylist")
);
const SingleVideo = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/single-video/SingleVideo"
        )
);
const HeroUnit = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/hero-unit/HeroUnitContainer"
        )
);
const TextAndCounterHeroUnit = React.lazy(
    () =>
        import(
            "@mediaspace/features/home-page/components/hero-unit/text-and-counter/TextAndCounterHeroUnit"
            )
);
const HtmlOnly = React.lazy(
    () => import("@mediaspace/features/home-page/components/html-only/HtmlOnly")
);
const TextOnly = React.lazy(
    () => import("@mediaspace/features/home-page/components/text-only/TextOnly")
);
const AdImages = React.lazy(
    () => import("@mediaspace/features/home-page/components/ad-images/AdImages")
);
const Registration = React.lazy(
    () => import("@mediaspace/features/home-page/components/registration/Registration")
);
const Channels = React.lazy(
    () => import("@mediaspace/features/home-page/components/channels/Channels")
);

const BannerCta = React.lazy(
    () => import("@mediaspace/features/home-page/components/banner-cta/BannerCta")
);

const render = getDsLayoutComponentRenderer(HomePage);

export {
    News,
    render,
    HeroUnit,
    TextAndCounterHeroUnit,
    SinglePlaylist,
    SingleVideo,
    HtmlOnly,
    TextOnly,
    AdImages,
    UpcomingSessions,
    Registration,
    Channels,
    BannerCta
};
