import { Button } from "@kaltura/ds-react-components";
import { Chat24Icon } from "@kaltura/ds-react-icons";
import {translatePlural} from "@mediaspace/shared/utils";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import {useButtonAnalytics} from "@mediaspace/hooks";

export interface CommentsProps {
    count: number;
}

export const Comments = ({ count }: CommentsProps) => {

    const sendButtonAnalytics = useButtonAnalytics();

    const onButtonClick = () => {
        sendButtonAnalytics("Media - Go to Comment", ButtonClickAnalyticsType.NAVIGATE);
        // TODO: scroll to the comments component
    }

    return <Button
        variant={"borderless"}
        size={"medium"}
        color={"secondary"}
        onClick={onButtonClick}
        startIcon={<Chat24Icon />}
        title={translatePlural("%1 comment", "%1 comments", [count])}
    >
        {count}
    </Button>;
};
