import {StyledPublishStatus} from "./styled-components";

export interface NoLinkIndicatorProps {
    icon: any;
    label: string;
}

/**
 * status indicator for cases where the indicator should act as a plain text
 */
const NoLinkIndicator = (props: NoLinkIndicatorProps) => {

    const {icon, label} = props;

    return (
        <StyledPublishStatus>
            {icon}
            {label}
        </StyledPublishStatus>
    )
}

export default NoLinkIndicator;
