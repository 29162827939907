import type { Theme } from "@mui/material";

interface Props {
    // the prop is marked as optional to be compatible with the standard styled() type definitions
    theme: Theme;
}

export const kafSystemWidth = (props: Props) => {
    const { theme} = props;
    return {
        // don't use breakpoints.between(), it doesn't work with asset consolidation
        // down("sm")
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5),
        }
    };
};
