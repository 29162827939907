import { Button, ButtonProps } from "@kaltura/ds-react-components";
import { useFormState } from "react-hook-form";
import { translate } from "@mediaspace/shared/utils";

const SubmitButton = (props: ButtonProps) => {
    const { loading, disabled } = props;
    const { isValid } = useFormState();

    return (
        <Button
            loading={loading}
            disabled={!isValid || disabled}
            type="submit"
            size={"medium"}
            variant={"pill"}
        >
            {translate("Save")}
        </Button>
    );
};

export default SubmitButton;
