import { UploadStatus } from "@mediaspace/shared/upload";
import { StyledBulkActionsRow, StyledLabel, StyledText } from "./StyleComponents";
import { translate } from "@mediaspace/shared/utils";
import Thumbnail from "./thumbnail/Thumbnail";
import BulkActionsButtons from "./bulk-actions-buttons/BulkActionsButtons";
import { useEffect, useState } from "react";
import { MediaUploadFileInfo } from "@mediaspace/shared/upload";

export interface BulkActionsProps {
    files: MediaUploadFileInfo[];
    onCancel: () => void;
    onPublish: () => void;
    onEdit: () => void;
}

const BulkActions = ({files, onCancel, onPublish, onEdit}: BulkActionsProps) => {

    const [actionFiles, setActionFiles] = useState<MediaUploadFileInfo[]>([]);

    useEffect(() => {
        setActionFiles(files.filter((file) => {
            const status = file.fileMetaData?.status;
            return status === UploadStatus.IN_QUEUE ||
                status === UploadStatus.UPLOADING ||
                status === UploadStatus.PROCESSING ||
                status === UploadStatus.READY
        }))
    }, [files]);

    return actionFiles.length <= 1 ? null :
        (<StyledBulkActionsRow className={"kms-ds-upload-media-bulk-actions-row"}>
            <Thumbnail filesCount={actionFiles.length} />
            <StyledLabel className={"kms-ds-upload-media-bulk-actions-row-thumbnail-label"}>
                <StyledText variant={"body1Highlight"}>{translate("All Media")}</StyledText>
            </StyledLabel>
            <BulkActionsButtons
                files={actionFiles}
                onCancel={onCancel}
                onPublish={onPublish}
                onEdit={onEdit}
            />
        </StyledBulkActionsRow>
    );
};

export default BulkActions;
