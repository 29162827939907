import React, {FunctionComponent, MouseEvent} from "react";
import { isEmpty } from "ramda";
import styled from "@emotion/styled";
import { formatters } from "@mediaspace/shared/utils";
import { Typography } from "@kaltura/ds-react-components";
import { alpha, StyledThumbnail } from "@mediaspace/shared/styled";
import { composeClasses } from "@mediaspace/shared/styled";
import clsx from "clsx";
import { getThumbnailClass, ThumbnailClasses } from "./thumbnailClasses";

type Props = {
    className?: string;
    classes?: Partial<ThumbnailClasses>;
    linkUrl?: string;
    thumbnailUrl?: string;
    duration?: number;
    width?: number | string;
    height?: number | string;
    timeDataString?: string;
    openInNewTab?: boolean;
    ariaLabelText?: string;
    tabIndex?: number;
    altText?: string;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
};

const slots = {
    root: ["root"],
    image: ["image"],
    duration: ["duration"],
};

const useUtilityClasses = (styleProps: Props) => {
    const { classes } = styleProps;

    return composeClasses(slots, getThumbnailClass, classes);
};

const StyledImage = styled(StyledThumbnail)(({ width, height }) => ({
    width,
    height,
    minWidth: width,
    overflow: "hidden",
}));

const StyledDuration = styled.span(({ theme }) => ({
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    borderRadius: theme.kaltura.shape.roundness1,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    padding: "4px 6px",
    left: theme.spacing(1),
    bottom: theme.spacing(1),
    position: "absolute",
    color: theme.palette.common.white,
}));

const StyledContainer = styled.div<{ webcast?: boolean }>(({ webcast }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 6,
    padding: "5px 10px",
    ...(webcast && {
        backgroundColor: "#dc430d",
    }),
}));

const StyledLink = styled.a<{
    width?: number | string;
    height?: number | string;
    noLink?: boolean;
    error?: boolean;
}>(
    ({
        width,
        height,
        noLink,
        error,
    }) => ({
        width,
        height,
        display: "block",
        position: "relative",
        textDecoration: "none",
        ...(noLink && {
            pointerEvents: "none",
            cursor: "default",
        }),
        ...(error && {
            [StyledImage as any]: {
                display: "none",
            },
        }),
        "&::before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
        },
    })
);

/**
 * Simple thumbnail component.
 */
export const Thumbnail: FunctionComponent<Props> = ({
    className = "",
    linkUrl = "",
    thumbnailUrl = "",
    classes: classesProps,
    duration,
    width = "inherit",
    height = "inherit",
    timeDataString,
    openInNewTab = false,
    ariaLabelText = undefined,
    tabIndex = undefined,
    altText = undefined,
    onClick
}) => {
    const [imageLoadError, setImageLoadError] = React.useState(false);
    const classes = useUtilityClasses({ classes: classesProps });
    const handleImageError = () => {
        setImageLoadError(true);
    };


    // if we have a duration string, show that instead of the entry duration.
    const showDuration = !timeDataString;
    const durationAvailable = showDuration && !isNaN(duration) && duration > 0;
    const linkAriaLabelProp = tabIndex !== -1 ? { 'aria-label': ariaLabelText } : {};
    return (
        <StyledLink
            error={imageLoadError}
            noLink={isEmpty(linkUrl)}
            width={width}
            height={height}
            className={clsx(className, classes.root)}
            href={linkUrl}
            target={openInNewTab ? "_blank" : ""}
            tabIndex={tabIndex}
            onClick={onClick}
            {...linkAriaLabelProp}
        >
            <StyledImage
                className={classes.image}
                width={width}
                height={height}
                src={thumbnailUrl}
                alt={altText ?? ariaLabelText}
                onError={handleImageError}
            />
            {timeDataString && (
                <StyledContainer>
                    <Typography variant={"caption"} paragraph={true}>
                        {timeDataString}
                    </Typography>
                </StyledContainer>
            )}
            {durationAvailable && (
                <StyledDuration
                    className={classes.duration}
                >
                    {formatters.formatSecondsToTime(duration)}
                </StyledDuration>
            )}
        </StyledLink>
    );
};
