import { createContext, ReactNode, useContext, useMemo, useState } from "react";

export interface MediaDrawerContextType {
    isOpen: boolean;
    content?: ReactNode;
    open: (content: ReactNode) => void;
    close: () => void;
    setOnCloseConfirmation: (confirmation?: () => boolean) => void;
}

export const MediaDrawerContext = createContext<MediaDrawerContextType | undefined>(undefined);

/**
 * Context for sharing mediaDrawer between components.
 * The provider for this context should be rendered in the top level of the application (see DsLayout).
 */
export const useMediaDrawer = (): MediaDrawerContextType => {
    const context = useContext(MediaDrawerContext);
    if (!context) {
        throw new Error("useMediaDrawer must be used within a MediaDrawerProvider");
    }
    return context;
};

/**
 * Provider for  MediaDrawerContext.
 */
export const MediaDrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState<ReactNode | null>(null);
    const [onConfirm, setOnConfirm] = useState<() => boolean>();

    const contextValue = useMemo<MediaDrawerContextType>(() => {
        const open = (content: ReactNode) => {
            setContent(content);
            setIsOpen(true);
        };

        const close = () => {
            let isConfirmed = true;
            if (onConfirm) {
                isConfirmed = onConfirm();
            }

            if (isConfirmed) {
                setContent(null);
                setOnConfirm(undefined);
                setIsOpen(false);
            }
        };

        const setOnCloseConfirmation = (confirmation?: () => boolean) => {
            setOnConfirm(() => confirmation);
        };

        return {
            isOpen,
            content,
            open,
            close,
            setOnCloseConfirmation,
        };
    }, [content, isOpen, onConfirm]);

    return <MediaDrawerContext.Provider value={contextValue}>{children}</MediaDrawerContext.Provider>;
};
