import styled from "@emotion/styled";
import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { Typography } from "@kaltura/ds-react-components";
import { composeClasses } from "@mediaspace/shared/styled";
import { HeaderLogoClasses, getHeaderLogoClass } from "./headerLogoClasses";

export enum LogoSize {
    small = "small",
    large = "large",
}

export interface HeaderLogoProps {
    className?: string;
    link?: string; // per $logo->logoLink(), without `href=`
    linkTarget?: "_blank"; // per $logo->logoLinkTarget()
    hasLogo: boolean; // $logo->hasLogo()
    altText?: string; // $logo->getAltText()
    logoUrl?: string; // $logo->getLogoUrl()
    size?: LogoSize;
    classes?: Partial<HeaderLogoClasses>;
}

const useUtilityClasses = (styleProps: Partial<HeaderLogoProps>) => {
    const { classes } = styleProps;

    const slots = {
        root: ["root"],
        link: ["link"],
        image: ["image"],
        title: ["title"],
    };

    return composeClasses(slots, getHeaderLogoClass, classes);
};

const StyledHeaderLogo = styled("div", {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "size";
    },
})<{ size: LogoSize }>(({ theme, size }) => ({
    maxHeight: 36,
    ...(size === LogoSize.large && {
        maxHeight: 48,
    }),

    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        ...(size === LogoSize.small && {
            maxHeight: 24,
        }),
    }
}));

const StyledLink = styled("a", {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "size";
    },
})<{ size: LogoSize }>(({ theme, size }) => ({
    fontSize: "36px",
    color: theme.kaltura.palette.tone8,
    display: "block",
    lineHeight: "1rem",
    textDecoration: "none",
    maxHeight: 36,
    ...(size === LogoSize.large && {
        maxHeight: 48,
    }),

    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        ...(size === LogoSize.small && {
            maxHeight: 24,
        }),
    }
}));

const StyledLogoImage = styled("img", {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "size";
    },
})<{ size: LogoSize }>(({ theme, size }) => ({
    maxHeight: 36,
    maxWidth: 140,
    ...(size === LogoSize.large && {
        maxHeight: 48,
        maxWidth: 343,
    }),

    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        ...(size === LogoSize.small && {
            maxHeight: 24,
            maxWidth: 75,
        }),
    }
}));

const StyledTextOnlyLogo = styled(Typography)(({ theme }) => ({
    margin: 0,
    color: theme.kaltura.palette.translucent.light,
}));

/**
 * Header Logo component
 */
export const HeaderLogo: FunctionComponent<HeaderLogoProps> = ({
    className = "",
    link,
    linkTarget,
    hasLogo,
    altText,
    logoUrl,
    size = LogoSize.small,
    ...rest
}) => {
    const classes = useUtilityClasses(rest);

    const linkAttributes = {
        href: link ? link : undefined,
        target: linkTarget ? linkTarget : undefined,
    };

    return (
        <StyledHeaderLogo size={size} className={clsx(className, classes.root)}>
            <StyledLink
                {...linkAttributes}
                className={classes.link}
                rel={"noopener noreferrer"}
                size={size}
            >
                {hasLogo && (
                    <StyledLogoImage
                        className={classes.image}
                        src={logoUrl}
                        alt={altText}
                        size={size}
                    />
                )}
                {!hasLogo && (
                    <StyledTextOnlyLogo
                        variant={"h2"}
                        component={"div"}
                        noWrap={true}
                        className={classes.title}
                    >
                        {altText}
                    </StyledTextOnlyLogo>
                )}
            </StyledLink>
        </StyledHeaderLogo>
    );
};
