import { SidebarMenuButton, SidebarMenuButtonProps } from "@kaltura/ds-react-layouts";
import { useButtonAnalytics } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";

export interface SidebarMenuButtonWithAnalyticsProps extends Omit<SidebarMenuButtonProps, "onClick"> {
    analyticsText: string;
}

export const SidebarMenuButtonWithAnalytics = ({ analyticsText, ...props }: SidebarMenuButtonWithAnalyticsProps) => {
    const sendButtonAnalytics = useButtonAnalytics();

    return <SidebarMenuButton {...props} onClick={() => sendButtonAnalytics("CnC - Sidebar - Open " + analyticsText, ButtonClickAnalyticsType.OPEN)} />;
};
