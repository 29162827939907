import {StyledModal, StyledModalContent, StyledButton, StyledImage, StyledTitle, StyledTypography, StyledDescription, StyledBackground} from "./StyledComponents";
import { translate, useMediaQuery, getModuleAssetUrl } from "@mediaspace/shared/utils";
import {Clear24Icon} from "@kaltura/ds-react-icons";
import {useTheme} from "@mediaspace/shared/styled";
import ReactHtmlParser from "@mediaspace/shared/ui/html-react-parser";

export type BadgesModalProps = {
    title: string,
    description: string,
    badgeIconUrl: string,
    showModal: boolean,
    onClose: () => void
}

const staticClassBase = "kms-ds-badges-modal";

const BadgesModal = (props: BadgesModalProps) => {

    const {showModal = false, title, description, badgeIconUrl, onClose} = props;

    const backgroundUrl = getModuleAssetUrl("badges", "confetti.png");

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

    return (
        <StyledModal open={showModal} fullScreen={false} className={`${staticClassBase}`}>
            <StyledModalContent>
                <StyledButton variant={"borderless"} onClick={onClose} className={`${staticClassBase}-close-button`}>
                    <Clear24Icon />
                </StyledButton>
                <StyledBackground src={backgroundUrl} />
                <StyledImage src={badgeIconUrl} />
                <StyledTitle variant={isSmallScreen ? "h3" : "h2"} className={`${staticClassBase}-title`}>
                    {title}
                </StyledTitle>
                <StyledTypography className={`${staticClassBase}-static-text`}>
                    {translate("How to earn this badge?")}
                </StyledTypography>
                <StyledDescription variant={"body1"} className={`${staticClassBase}-description`}>
                    {ReactHtmlParser(description)}
                </StyledDescription>
            </StyledModalContent>
        </StyledModal>
    )
}

export default BadgesModal;
