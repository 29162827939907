import { ReactNode, useState } from "react";
import { Tooltip, tooltipClasses, TooltipProps } from "@kaltura/ds-react-components";
import TruncateMarkup from "react-truncate-markup";
import styled from "@emotion/styled";

/*
21/7/2024 Accessibility issues:
 there is a violation about adding aria-label to a span that has no defined role.
 MUI tooltip adds aria-label to the HTML element it wraps.
 we need the span wrap, because MUI Tooltip crashes without it (probably during
 rendering of the truncated content there is no other element for the tooltip to work with)
 moreover, the screen reader doesn't read the aria-label when it's on a role-less element.
 I tried the following:

 1. no aria-label: adding an empty string as aria-label to the outer span element
    is not overridden by MUI, violation is solved.
    however, screen reader has no way to read the full text.

2. instead of inlining the span with TruncateMarkup, create a component that takes
    children as a prop, clone the child element, adding the {...props} passed by
    the tooltip component (hence - aria-label).
    MUI Tooltip attaches listeners to the underlying DOM element, and since we
    are cloning the element and rendering that instead, listeners get lost.
 */

export interface TruncateWithTooltipProps {
    text: ReactNode;
    className?: string;
    tooltipPlacement?: TooltipProps["placement"];
    tokenize?: string;
    children: ReactNode;
    lines?: number;
    role?: string;
}

const StyledTooltip = styled(Tooltip)(({theme}) => ({
    [`&.${tooltipClasses.tooltip}`]: {
        fontWeight: theme.kaltura.typography.fontWeightBold
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        borderTopLeftRadius: theme.kaltura.shape.roundness1
    }
}));

/**
 * This component shows truncated or not truncated text.
 * If the text is truncated the full text is shown inside a tooltip.
 * children should be wrapped by a single DOM element
 */
export const TruncateWithTooltip = ({text, className, tooltipPlacement = "bottom", tokenize, children, lines = 1, role}: TruncateWithTooltipProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const handleTruncate = (wasTruncated: boolean) => {
        setShowTooltip(wasTruncated);
    }

    return (
        <StyledTooltip className={className} title={showTooltip ? text : ""} placement={tooltipPlacement}>
            <span className={"chromatic-ignore"} role={role}>
                <TruncateMarkup lines={lines} tokenize={tokenize} onTruncate={handleTruncate}>
                    {children}
                </TruncateMarkup>
            </span>
        </StyledTooltip>
    )
};
