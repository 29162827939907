import ReactDOM from "react-dom";
import {lazy, Suspense} from "react";
import {ThemeProvider} from "@mediaspace/shared/styled";
import {Config, ConfigProvider} from "@mediaspace/shared/data-kms-config";
import type { WaitingRoomProps } from "@mediaspace-react-components/features-waiting-room";

const WaitingRoom = lazy(
    () => import("@mediaspace-react-components/features-waiting-room")
);

export const render = (
    containerId: string,
    { context, ...props }: WaitingRoomProps & { context: Config }
) => {
    const { theming } = context;

    ReactDOM.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode}>
                    <WaitingRoom {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(containerId)
    );
};
