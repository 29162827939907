import {MenuItem} from "@kaltura/ds-react-components";
import {useState} from "react";
import AddToPlaylistModal, {AddToPlaylistProps} from "./modal/AddToPlaylistModal";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import {useButtonAnalytics} from "@mediaspace/hooks";

export interface AddToPlaylistMenuItemProps extends AddToPlaylistProps {
    label: string;
}

/**
 * Add to playlist menu item for media-page title area actions menu
 */
export const AddToPlaylistMenuItem = (props: AddToPlaylistMenuItemProps) => {

    const {label, autoFocus, ...modalProps} = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const sendButtonAnalytics = useButtonAnalytics();

    const openAddToPlaylistModal = () => {
        sendButtonAnalytics("Media Actions -  Add to Playlist", ButtonClickAnalyticsType.ADD);
        setIsOpen(true);
    }

    return (
        <>
            <MenuItem
                key={"add-to-playlist"}
                onClick={openAddToPlaylistModal}
                autoFocus={autoFocus}
            >
                {label}
            </MenuItem>

            <AddToPlaylistModal
                {...modalProps}
                isOpen={isOpen}
            />
        </>
    )
}
