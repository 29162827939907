import { KalturaPrivacyType } from "kaltura-typescript-client/api/types";

/**
 * membership type for categories - fits Kms_Type_Category_Membership
 */
export enum KmsTypeCategoryMembership {
    //category membership
    /**
     * category can be accessed by all users
     */
    OPEN = KalturaPrivacyType.all,
    /**
     * category can be accessed by all authenticated users
     */
    RESTRICTED = KalturaPrivacyType.authenticatedUsers,
    /**
     * category can be accessed by category members only
     */
    PRIVATE = KalturaPrivacyType.membersOnly,

    //channel membership
    /**
     * shared repository
     */
    SHARED = 4,
    /**
     * public restricted channel
     */
    PUBLIC_RESTRICTED = 5,
    /**
     * public open channel
     */
    PUBLIC_OPEN = 6
}
