import { getTags, getTagSearchUrl, getTagGlobalSearchUrl, stripTags } from "@mediaspace/shared/utils";
import styled from "@emotion/styled";
import { Tag } from "@kaltura/ds-react-components";
import { useConfig } from "@mediaspace/shared/data-kms-config";

export interface EventsListItemTagsProps {
    className?: string,

    /**
     * tags to show
     */
    tags: string,

    /**
     * number of tags to show
     */
    count?: number,

    /**
     * show number of truncated tags
     */
    countRest?: boolean,

    /**
     * if true, clicking a tag will link to relevant search page
     */
    clickable?: boolean,

    /**
     * when tags are clickable, additional click callback for
     * context-aware actions, like analytics.
     * navigation is handled in this component.
     * @param tag
     */
    additionalClickHandler?: (tag: string) => void;
}

const TagList = styled("ul")(({ theme }) => ({
    margin: 0,
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap"
}));


/**
 * Event Session Item tags
 */
export function EventsListItemTags ({
    className = "",
    tags,
    count = 2,
    countRest = false,
    clickable = false,
    additionalClickHandler
}: EventsListItemTagsProps) {

    const tagsArray = getTags(tags);
    const context = useConfig();
    const { globalSearch } = context;

    // remove displayname_* && __ prefix tags
    const relevantTags = tagsArray.filter(
            (tag: string) =>
                !tag.includes("displayname_") && !tag.startsWith("__")
        );

    const tagsComponents = relevantTags
        .slice(0, count) // get only X tags
        .map((tag: string, index: number) => {
            // strip tags before we do anything here.
            const tagNoHtml = stripTags(tag);

            return (
                <Tag label={tagNoHtml} key={index} role={"listitem"}
                    clickable={clickable}
                    onClick={clickable ?
                        () => {
                            if (additionalClickHandler) {
                                additionalClickHandler(tag);
                            }
                            document.location.href = globalSearch ? getTagGlobalSearchUrl(tag) : getTagSearchUrl(tag)
                    } :
                    undefined
                    }
                />
            );
        });

    if (tagsComponents.length === 0) {
        // if there are no tags to display, don't render the wrapper
        return null;
    }

    const rest = relevantTags.length - count;

    return <TagList className={className}>
        {tagsComponents}
        {(countRest && rest > 0) && (
            <Tag label={`+ ${rest}`} key={"rest"} role={"listitem"} />
        )}
    </TagList>;
};
