import { MenuItem } from "@kaltura/ds-react-components";
import { ActionsMenuItemProps } from "../../ActionsMenu";
import { useState } from "react";
import { ReachEntry, ReachEntryProps } from "@kaltura/unisphere-reach-widget";

interface ReachMenuItemProps extends ReachEntryProps {
    label: string;
    editCaptionUrl?: string;
}

/**
 * An item for ActionsMenu for opening Reach modal.
 */
export const ReachMenuItem = (props: ReachMenuItemProps & ActionsMenuItemProps) => {

    const [showModal, setShowModal] = useState(false);
    const {onCloseMenu, label, autoFocus, editCaptionUrl, ...reachProps} = props;

    const onEditCaption = (assetId: string) => {
        if (editCaptionUrl) {
            window.location.href = editCaptionUrl.replace("{assetID_placeholder}", assetId);
        }
    }

    const onClose = () => {
        onCloseMenu?.();
    }

    const widgetProps = {...reachProps, onEditCaption: onEditCaption, onClose: onClose};

    const handleClick = () => {
        setShowModal(true);
    }


    return (
        <>
            <MenuItem onClick={handleClick} autoFocus={autoFocus}>
                {label}
            </MenuItem>
            {showModal && (
                <ReachEntry {...widgetProps} />
            )}
        </>
    );
};
