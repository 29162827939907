import { useCallback, useEffect, useState } from "react";

/**
 * Tracks width/height of a DOM element.
 *
 * The element must be visible (not display:none) to be able to calculate the size.
 *
 * Note that there's one frame until getting the ref to the element,
 * so the hook will return undefined instead of width and height before getting the ref.
 *
 * The "elementRef" prop of the returned object should be used as element's "ref" property. e.g.
 * const {elementRef, width, height} = useElementSizeObserver();
 * return <div ref={elementRef}>Some content</div>;
 */
export const useElementSizeObserver = <T extends HTMLElement = HTMLDivElement>() => {
    const [element, setElement] = useState<T | null>(null);

    const [width, setWidth] = useState<number | undefined>(undefined);
    const [height, setHeight] = useState<number | undefined>(undefined);

    const updateSize = useCallback((element: T) => {
        setWidth(element.offsetWidth);
        setHeight(element.offsetHeight);
    }, [setWidth, setHeight]);

    const elementRef = useCallback((element: T | null) => {
        if (element) {
            updateSize(element);
        }
        setElement(element);
    }, [updateSize, setElement]);

    useEffect(() => {
        if (!element) {
            return;
        }

        updateSize(element);

        const {ResizeObserver} = window as any;
        if (ResizeObserver) {
            // The timeout is to prevent the recursive resize observer error.
            // See https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
            const observer = new ResizeObserver(() => setTimeout(() => updateSize(element)));
            observer.observe(element);
            return () => {
                observer.unobserve(element);
            };
        }

        return;
    }, [element, updateSize]);

    return {elementRef, width, height};
};
