import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
// noinspection ES6PreferShortImport
import { EventsListItemTags } from "../event-list-item-tags/EventsListItemTags";
import { Truncate } from "@mediaspace/shared/ui";
import ActionSection from "./action-section/ActionSection";

export const StyledEventListItem = styled("div")(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    borderRadius: theme.kaltura.shape.roundness2,
    backgroundColor: theme.kaltura.palette.surfaces.paper,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        display: "block"
    },
}));

export const SchedulingContainer = styled("div")(({ theme }) => ({
    flex: "1 1 0",
    maxWidth: 180,
    marginTop: 3,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        display: "flex",
        flexDirection:  "row-reverse",
        justifyContent: "flex-end",
        alignItems: "start",
        maxWidth: "unset",
        marginTop: 0,
        gap: theme.spacing(1),

    },
}));

export const SchedulingLine = styled(Typography, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "content";
    }
})<{ content: "time" | "date" }>(({ theme, content }) => ({
    color: theme.kaltura.palette.tone2,
    display: "block",
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        display: "inline",
        ...(content === "time" && {
            order: 2,
        }),
        ...(content === "date" && {
            order: 3,
        }),
    },

}));

export const StatusLive = styled("div")(({ theme }: { theme?: any }) => ({
    display: "inline-block",
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderRadius: theme.kaltura.shape.roundness1,
    padding: 3,
    backgroundColor: theme.kaltura.palette.danger.main,
    textTransform: "uppercase",
}));

export const Container = styled("div")(({ theme }) => ({
    flex: "2 2 0",
    backgroundColor: theme.kaltura.palette.surfaces.paper,
    borderTopLeftRadius: theme.kaltura.shape.roundness1,
    borderBottomLeftRadius: theme.kaltura.shape.roundness1,
    overflow: "hidden",
}));

export const EntryLink = styled("a")<{ disabled?: boolean }>(({ disabled, theme }) => ({
    textDecoration: "none",
    "&:hover, &:focus": {
        color: theme.kaltura.palette.tone1
    },
    ...(disabled && {
        pointerEvents: "none",
    }),
}));

export const Name = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone1,
    marginTop: 0,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginBottom: theme.spacing(1),
    }
}));

export const Tags = styled(EventsListItemTags)(({ theme }) => ({
    padding: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginBottom: theme.spacing(1),
    }
}));

export const Description = styled(Truncate)(({ theme, lines = 0 }) => ({
    color: theme.kaltura.palette.tone2,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(3),
    "&.truncation-error" :{
        maxHeight: `${lines*1.5}em`,
        overflow: "hidden",
    },
    "& a": {
        fontWeight: theme.kaltura.typography.fontWeightBold,
        textDecoration: "none"
    }
}));

export const Thumbnail = styled("img")(({ theme }) => ({
    width: 224,
    height: 126,
    borderRadius: theme.kaltura.shape.roundness2,
}));

export const StyledActionSection = styled(ActionSection)(({ theme }) => ({
    marginTop: theme.spacing(3)
}));
