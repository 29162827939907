import ReactDOM from "react-dom";
import React, { Suspense } from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import type { RestreamDestinationsTypes } from "@mediaspace/features/restream-destinations";

const RestreamDestinations = React.lazy(() => import("@mediaspace/features/restream-destinations"));

export const render = (id: string, props: RestreamDestinationsTypes & { context: Config }) => {
    const { theming } = props.context;

    ReactDOM.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode} scoped={true}>
                    <RestreamDestinations {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};
