/**
 * webcast helper class for common kwebcast functions
 */
import { KmsTypeSchedulingTimeInterval } from "@mediaspace/shared/types";

export enum WebcastStatus {
    noScheduling = "",
    live = "live",
    past = "past",
    future = "future",
}

export default class WebcastHelper {
    /**
     * get current entry status per custom metadata
     * @param schedulingData
     */
    static getLiveStatus = (schedulingData: KmsTypeSchedulingTimeInterval) => {
        const { start, end } = schedulingData;
        const now = Date.now() / 1000;
        if (end.timestamp < now) {
            return WebcastStatus.past;
        }
        if (start.timestamp > now) {
            return WebcastStatus.future;
        }
        return WebcastStatus.live;
    };
}
