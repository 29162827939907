import { systemWidthSize } from "@mediaspace/shared/styled";
import clamp from "lodash/clamp";

/**
 * Custom hook to get carousel items size definitions
 */
export const useCarouselItemThumbnailSize = (minPossibleCardsNumberInSlides: number) => {

    const thumbnailDimensions = (
        size: keyof typeof systemWidthSize,
        numberOfThumbnails: number,
        spacing: number
    ) => {
        const totalWidth = systemWidthSize[size];
        const width = Math.floor(
            (totalWidth - 8 * spacing * (numberOfThumbnails - 1)) /
            numberOfThumbnails
        );
        const height = Math.round((width * 9) / 16);
        return { width: width, height: height };
    };

    const smallSize = thumbnailDimensions("md", 3, 2);
    const mediumSize = thumbnailDimensions(
        "md",
        clamp(minPossibleCardsNumberInSlides, 2, 3),
        2
    );
    const largeSize = thumbnailDimensions(
        "lg",
        minPossibleCardsNumberInSlides,
        4
    );
    const extraLargeSize = thumbnailDimensions(
        "xl",
        minPossibleCardsNumberInSlides,
        4
    );

    return { smallSize: smallSize, mediumSize: mediumSize, largeSize: largeSize, extraLargeSize: extraLargeSize };
}
