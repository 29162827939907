// Source: https://github.com/kaltura/kep-ve-management/blob/master/ve-management/apps/ve-client/src/app/app-ui/shared-ui/toasts-provider.tsx
import { ToastOptions, ToastsContext } from "./ToastsContext";
import { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Snackbar } from "@mui/material";
import { Toast } from "@kaltura/ds-react-components";
import ReactHtmlParser from "../html-react-parser";

export interface ToastsProviderProps {
    children?: ReactNode;
    toastDuration: number;
    toastsZIndex?: number;
}

export interface ToastObject extends ToastOptions {
    time: number;
}

const delay = 100;

/**
 * Wrapper component for toast notifications:
 * - Renders the snackbar with toasts.
 * - Provides context for child elements to push new notifications there - see ToastsContext.
 *
 * Usage:
 * ```
 * <ToastsProvider toastDuration={4000}>
 *     <PageContents/>
 * </ToastsProvider>
 *
 * const PageContents = () => {
 *     const { showToast } = useToastsContext();
 *
 *     return <Button onClick={() => showToast({severity: "info", message: "Hi there!"});}>
 *         Notify me!
 *     </Button>;
 * };
 * ```
 */
export const ToastsProvider = (props: ToastsProviderProps) => {
    const [toastsQueue, setToastsQueue] = useState<ToastObject[]>([]);
    const [currentToast, setCurrentToast] = useState<ToastObject | null>(null);

    useEffect(() => {
        if (currentToast) {
            return;
        }
        setTimeout(() => {
            const current = toastsQueue.pop();
            if (!current) {
                return;
            }
            setCurrentToast(current);
        }, delay); // todo [sa] closing animation duration
    }, [toastsQueue, currentToast]);

    const handleClose = useCallback((event?: Event | SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            //return;
        }
        setCurrentToast(null);
    }, []);

    const toastsContextValue = useMemo(() => {
        const showToast = (options: ToastOptions): void => {
            setToastsQueue((prev) => [
                ...prev,
                {
                    ...options,
                    time: new Date().getMilliseconds(),
                },
            ]);
        };

        return {
            showToast,
        };
    }, []);

    const isMessageString = typeof(currentToast?.message) === 'string';

    return (
        <ToastsContext.Provider value={toastsContextValue}>
            {props.children}
            <Snackbar
                open={!!currentToast}
                onClose={handleClose}
                autoHideDuration={props.toastDuration}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                {currentToast ? (
                    <Toast key={currentToast.time} severity={currentToast.severity} onClose={handleClose}>
                        {!isMessageString && (<span>{currentToast.message}</span>)}  
                        {isMessageString &&  ReactHtmlParser(currentToast.message as string)}                                              
                    </Toast>
                ) : (
                    <span></span>
                )}
            </Snackbar>
        </ToastsContext.Provider>
    );
};
