/**
 * data stubs for entry metadata components
 */
import type { KmsTypePresenter } from "@mediaspace/shared/types";

const presentersMetadata: KmsTypePresenter[] = [
    {
        id: "aaaaaaaaaaaaa",
        name: "Real Name",
        link: "http://google.com",
        title: "vice president elect of content &amp; joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy joy",
        bio:
            "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " +
            "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco " +
            "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " +
            "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco " +
            "laboris nisi ut aliquip ex ea commodo consequat",
        image: "",
    },
    {
        id: "bbbbbbbbbbbbbbbb",
        name: "Presenter &amp; Name No Link & Bio",
        link: "",
        title: "vice president elect of content &amp; joy",
        bio: "",
        image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
    },
    {
        id: "ccccccccccccccc",
        name: "Presenter- large image",
        link: "",
        title: "vice president elect of content &amp; joy",
        bio: "",
        image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/700/height/700/type/3",
    },
    {
        id: "ddddddddddddddd",
        name: "Presenter - no image",
        link: "",
        title: "mystery man. or woman.",
        bio: "",
        image: "public/img/avatar.png",
    },
    {
        id: "eeeeeeeeeeeeeee",
        name: "Presenter",
        link: "",
        title: "some title",
        bio: "",
        image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/700/height/700/type/3",
    },
    {
        id: "fffffffffffffff",
        name: "Presenter",
        link: "",
        title: "some title",
        bio: "",
        image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/700/height/700/type/3",
    },
];

const categoriesMetadata = [
    {
        href: "channel/channels/13745002",
        label: "channels name",
        value: "channels",
        attributes: {
            class: "entry__appears-in badge badge-info",
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: "MediaSpace > site > channels",
        },
        order: 0,
        id: null,
        type: null,
        isMulti: null,
    },
    {
        href: "channel/context/30869381",
        label: "context category",
        value: "context",
        attributes: {
            class: "entry__appears-in badge badge-info",
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: "context",
        },
        order: 0,
        id: null,
        type: null,
        isMulti: null,
    },
    {
        href: "channel/channels/13745002",
        label: "channels &amp; name",
        value: "channels",
        attributes: {
            class: "entry__appears-in badge badge-info",
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: "MediaSpace > site > channels",
        },
        order: 0,
        id: null,
        type: null,
        isMulti: null,
    },
];

const entryThumbnails = {
    "1_yqzqszj8": {
        src: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/478/height/269/type/3",
        name: "this is not really my name",
        noAccess: null,
    },
    "1_yqzqszj7": {
        noAccess: true,
    },
};

const textSelectMetadata = [
    { value: "value &amp; one" },
    { value: "value two" },
    { value: "value three" },
    { value: "value four" },
    { value: "value five" },
    { value: "value six" },
    { value: "value seven" },
];

const textMetadata = [
    {
        value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " +
            "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco " +
            "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt " +
            "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco " +
            "laboris nisi ut aliquip ex ea commodo consequat.",
        order: 0,
        id: "",
        type: "",
        isMulti: false,
    },
];

const textMetadataWithMarkups = [
    {
        value:
            "<b>Lorem &amp;  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</b> " +
            "<a href='#'>ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</a> " +
            "<i>laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</i> " +
            "<u> ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</u>" +
            "<li> laboris nisi ut aliquip ex ea commodo consequat.</li>",

        order: 0,
        id: "",
        type: "",
        isMulti: false,
    },
];

const entryIdsMetadata = [
    {
        value: "1_yqzqszj8",
    },
    {
        value: "1_yqzqszj8",
    },
    {
        value: "1_yqzqszj7",
    },
];

const dateMetadata = [
    {
        value: "12/28/2018",
        type: "null",
    },
];

export {
    presentersMetadata,
    categoriesMetadata,
    entryThumbnails,
    textSelectMetadata,
    textMetadata,
    textMetadataWithMarkups,
    entryIdsMetadata,
    dateMetadata,
};
