import { addBaseUrl, postKmsData } from "@mediaspace/shared/utils";
import { ChangeOwner, ChangeOwnerProps } from "./ChangeOwner";

export interface ChangeOwnerContainerProps extends Omit<ChangeOwnerProps, "setOwner">{
    entryIds: string[];
    actionUrl: string;
}

export function ChangeOwnerContainer({ entryIds, actionUrl, ...rest }: ChangeOwnerContainerProps) {
    const url = addBaseUrl(actionUrl);

    return (
        <ChangeOwner
            setOwner={
                (userId, keepEditPermission) => {
                    return postKmsData(url,
                        { entryIds: entryIds, userId: userId, keepEditPermission: keepEditPermission })
                }
            }
            entryIds={entryIds}
            {...rest}
        />
    );
}

