import { KmsTypePublishCategory } from "@mediaspace/shared/types";
import { CategoryType, galleryLabel } from "../../../../../../utils";
import { CategoryCheckbox, CategoryCheckboxProps } from "./../category-checkbox/CategoryCheckbox";

interface GalleryCheckboxProps extends Omit<CategoryCheckboxProps, "category" | "tooltipText" | "categoryType"> {
    gallery: KmsTypePublishCategory;
    items: { [key: string]: GalleryCheckboxProps };
}

/**
 * gallery checkbox - recursively render checkbox and its items
 */
export const GalleryCheckbox = (props: GalleryCheckboxProps) => {
    const { isChecked, onCategoryChange, gallery, disabled = false, depth, items } = props;

    const childItems = Object.entries(items);

    return (
        <>
            {gallery && (
                <CategoryCheckbox
                    category={gallery}
                    categoryType={CategoryType.gallery}
                    tooltipText={galleryLabel(gallery)}
                    depth={depth}
                    disabled={disabled}
                    isChecked={isChecked}
                    onCategoryChange={onCategoryChange}
                />
            )}
            <>
                {childItems.map(([id, item]) => {
                    return (
                        <div key={item.gallery.id}>
                            <GalleryCheckbox {...props} {...item} depth={gallery ? depth + 1 : depth} />
                        </div>
                    );
                })}
            </>
        </>
    );
};
