import React, { useState } from "react";
import { Button, ModalTitle } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";
import { ModalProps, StyledModal, StyledModalActions, StyledModalContent } from "../StyledModal";
import { SaveType } from "../../../utils";

/**
 * Publish Modal - external-tab confirmation modal
 */
export function ExternalUnpublishConfirmModal(props: ModalProps) {
    const { onSubmit, onClose, entryIds } = props;
    const [processing, setProcessing] = useState<boolean>(false);

    const handleSubmit = async () => {
        setProcessing(true);
        onSubmit?.({entryIds: entryIds}, SaveType.crossApplicationUnpublish)
    };

    return (
        <StyledModal
            open={true}
            className="kms-ds-publish-modal-externalunpublish"
            onMouseLeave={(event) => event.stopPropagation()}
        >
            <ModalTitle>{translate("Unpublish from all applications")}</ModalTitle>
            <StyledModalContent>
                {translate(
                    "This action will unpublish the media from all applications. Are you sure you want to continue?"
                )}
            </StyledModalContent>

            <StyledModalActions>
                <Button variant={"borderless"} onClick={onClose} disabled={processing}>
                    {translate("Cancel")}
                </Button>
                <Button color={"danger"} onClick={handleSubmit} loading={processing} disabled={processing}>
                    {translate("Unpublish")}
                </Button>
            </StyledModalActions>
        </StyledModal>
    );
}
