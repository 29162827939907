import { useEffect, useState } from "react";
import { ThemeProvider } from "@kaltura/ds-react-theme";
import { CustomizationsProvider } from "@kaltura/ds-react-utils";
import { kmsCookie, booleanProp } from "@mediaspace/shared/utils";

const components = {
	button: {
		globalClasses: {
			props: ["variant", "color", "loading", "size", "disabled"],
			booleanTrueValue: "on",
			booleanFalseValue: "off",
		},
	},
};

/**
 * KMS Components Theme Provider - a wrapper to kaltura-ds ThemeProvider,
 * with CustomizationsProvider set to kms customization requirements.
 */
const KMSThemeProvider: typeof ThemeProvider = (props) => {
	const { children, ...rest } = props;
	const [highContrast, setHighContrast] = useState(booleanProp(kmsCookie("kms-contrast") as string));

    const setHighContrastCookie = () => {
    	setHighContrast(booleanProp(kmsCookie("kms-contrast") as string));
    }

    // subscribe to high contrast event (dispatched by a menu item)
    useEffect(() => {
    	document.addEventListener("kms-contrast", setHighContrastCookie);

        return () => {
            document.removeEventListener("kms-contrast", setHighContrastCookie);
        };
    }, []);

	return (
		<ThemeProvider {...rest} highContrast={highContrast}>
			<CustomizationsProvider components={components}>
				{children}
			</CustomizationsProvider>
		</ThemeProvider>
	);
};

export { KMSThemeProvider as ThemeProvider };
