import { useEffect } from "react";
import { CncProps } from "./CncProps";
import { useProcessedCncConfig } from "./useProcessedCncConfig";
import { baseUrl, KMS_GLOBAL } from "@mediaspace/shared/utils";

export const FloatedCnc = (props: CncProps) => {
    // Add config properties that depend on time/state
    const fulfilledConfig = useProcessedCncConfig(props);

    useEffect(() => {
        try {
            const floater = (window as any).KalturaEventsPlatform.collaboration.widgets.floater.new(fulfilledConfig);
            // Old variable, for legacy code compatibility
            KMS_GLOBAL.floater = floater;
            // New variable, unifies floating and embedded C&C
            KMS_GLOBAL.cncWidget = floater;
            floater.render();
            floater.addOnRequestCookieConsent(() => {
                (window as any).GLOBAL_KMS?.shortbread?.customizeCookies?.();
            });
            floater.addOnNavigate(({ contextId, openInNewTab }: any) => {
                if (contextId) {
                    const isEntry = /^[0-1]_[a-zA-Z0-9]{8}$/.test(contextId);
                    const url = isEntry ? baseUrl + "/media/" + contextId : baseUrl + "/channel//" + contextId;
                    window.open(url, openInNewTab ? "_blank" : "_self");
                }
            });

            return () => {
                floater.unmount();
            };
        } catch (err) {
            console.error("Failed to load chat widget:", err);
        }
        // the linter wants the function to always have a "return" statement
        return;
    }, [fulfilledConfig]);

    return null;
};
