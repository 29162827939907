import { useEffect, useState } from "react";
import Player, { PlayerProps } from "./Player";

export interface RaptPlayerProps extends PlayerProps {
    /**
     * media the player should load.
     * either entryId or playlistId should be present.
     */
    entryId?: string;
}

declare global {
    interface Window {
        kalturaPlayer: any;
        PathKalturaPlayer: any;
    }
}

/**
 *  wrapper for v7 rapt player.
 *  unlike the regular player, rapt player loadMedia method doesn't return a promise, the player
 *  doesn't include a 'configure' method and dispatches an event with different name,
 *  so it's being handled in a separated component
 */
export function RaptPlayer({entryId, onReady, ...rest}: RaptPlayerProps) {

    const [player, setPlayer] = useState<any>();

    const loadMedia = (player: any) => {
        if (player && entryId && !player.isInitialized) {
            player.loadMedia({
                playlistId: entryId
            });
        }
    };

    useEffect(() => {
        /*
         * The "player" state is being initialized in the "ready" event of the child component.
         * The "media.entryId" property may be changed by the parent component - it's a sign that the media
         * should be switched.
         * The "player.loadMedia()" method should be called when the player is ready and each time the entry ID changes.
         */
        loadMedia(player);

    }, [loadMedia, entryId, player])

    const handleReady = (player: any, playerId: string) => {

        document.dispatchEvent(
            new CustomEvent("kms_raptPlayerReady", { detail: { id: playerId } })
        );

        if (onReady) {
            onReady(player, playerId);
        }
        // setting the player will trigger loadMedia
        setPlayer(player);
    };
    return <Player {...rest} onReady={handleReady} />;
}

export default RaptPlayer;
