import { MediaUploadFileInfo } from "@mediaspace/shared/upload";
import FileRow, {FileRowProps} from "./fileRow/FileRow";
import BulkActions from "./bulk-actions/BulkActions";
import { useState } from "react";

export interface FileRowsProps extends Pick<FileRowProps, "editForm" | "onCancel" | "onPublish" | "onSubmit" | "onRemove"> {
    files: MediaUploadFileInfo[];
    onCancelAll: () => void;
    onPublishAll: () => void;
    onEditAll: () => void;
}

const FileRows = ({ files, onCancelAll, onPublishAll, onEditAll, ...rest }: FileRowsProps ) => {
    const [fileOpenForEdit, setFileOpenForEdit] = useState<string|undefined>(files.length === 1 ? files[0].fileMetaData.tokenId : undefined);
    const [fileWantsToOpenForEdit, setFileWantsToOpenForEdit] = useState<string>();

    return (
        <>
            <BulkActions files={files} onCancel={onCancelAll} onPublish={onPublishAll} onEdit={onEditAll} />
            {files.concat().reverse().map((file) => {
                return (
                    <FileRow
                        {...rest}
                        key={(`file_${file.fileMetaData.tokenId}`)}
                        fileInfo={file}
                        isOpen={fileOpenForEdit === file.fileMetaData.tokenId}
                        shouldClose={!!fileWantsToOpenForEdit}
                        onEdit={(tokenId: string) => {
                            if (!fileOpenForEdit) {
                                setFileOpenForEdit(tokenId);
                            }
                            else {
                                setFileWantsToOpenForEdit(tokenId);
                            }
                        }}
                        onClose={(tokenId: string) => {
                            if (fileOpenForEdit === tokenId) {
                                if (fileOpenForEdit === fileWantsToOpenForEdit) {
                                    // close all (specifically current) panels
                                    setFileOpenForEdit(undefined);
                                }
                                else {
                                    // open next panel
                                    setFileOpenForEdit(fileWantsToOpenForEdit);
                                }
                                // reset:
                                setFileWantsToOpenForEdit(undefined);
                            }
                        }}
                    />
                );
            })}
        </>
    );
};

export default FileRows;
