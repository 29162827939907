

const loadPlayerLib = (
    scriptUrl: string,
    onSuccess: () => void,
) => {
    // remove traces of possible previous lib
    window['KalturaPlayer'] = undefined;
    let script = document.getElementById("playerLibLoader");
    if (script) {
        script.parentNode.removeChild(script);
    }

    // load new lib
    script = document.createElement('script');
    script.setAttribute("id", "playerLibLoader");
    script.setAttribute('src', scriptUrl);
    document.head.appendChild(script);

    // check if ready
    const intervalID = setInterval(() => {
        if (typeof window['KalturaPlayer'] !== "undefined") {
            clearInterval(intervalID);
            onSuccess();
        }
    }, 50);
}

export default loadPlayerLib;
