import { Box } from "@mediaspace/shared/styled";
import styled from "@emotion/styled";
import { WithVerticalTimeBar } from "./WithVerticalTimeBar";
import { Badge } from "@kaltura/ds-react-components";
import { translate, translatePlural } from "@mediaspace/shared/utils";

/*
 * Channel TV agenda item - label for a break between 2 sessions (e.g. "30 minutes break")
 */

export interface SidebarSessionBreakProps {
    start: number;
    end: number;
    min: number;
    max: number;
}

export const SidebarSessionBreak = ({ start, end, min, max }: SidebarSessionBreakProps) => {
    const breakTime = end - start;

    if (breakTime < min || breakTime > max) {
        return null;
    }

    const totalMinutes = Math.round(breakTime / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    const formatted =
        hours === 0
            ? translatePlural("%1 minute break", "%1 minutes break", [minutes])
            : minutes === 0
            ? translatePlural("%1 hour break", "%1 hours break", [hours])
            : translate("%1 and %2 break", [
                  translatePlural("%1 hour", "%1 hours", [hours]),
                  translatePlural("%1 minute", "%1 minutes", [minutes]),
              ]);

    return (
        <WithVerticalTimeBar start={start} end={end}>
            <StyledContainer>
                <StyledBadge label={formatted} size={"medium"} />
            </StyledContainer>
        </WithVerticalTimeBar>
    );
};

const StyledContainer = styled(Box)({
    display: "flex",
});

const StyledBadge = styled(Badge)({
    // Increase rule's priority to override media query rules
    "div&": {
        width: "100%",
        maxWidth: "none",
    },
});
