import React from "react";
import clamp from "lodash/clamp";
import {
    EventPlatformEntry,
    TimeDisplay,
} from "@mediaspace/shared/types";
import {
    translateTime,
    translate,
    formatEntryThumbnailUrl,
    stripTags,
    WebcastHelper,
    WebcastStatus,
    useMediaQuery, durationToHuman
} from "@mediaspace/shared/utils";
import styled from "@emotion/styled";
import { alpha, systemWidthSize, useTheme } from "@mediaspace/shared/styled";
import { Thumbnail } from "../thumbnail/Thumbnail";
import { useConfig } from "@mediaspace/shared/data-kms-config";
import {
    Data,
    DataContainer,
    ItemDetailsWrapper,
    ItemLink, StatusTag, StyledMediaImage, StyledTruncatedDescription,
    StyledTruncatedName
} from "./CarouselItemStyledComponents";
import { useButtonAnalytics, useCarouselItemThumbnailSize, useEntryUrl } from "@mediaspace/hooks";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { KalturaEntryType } from "kaltura-typescript-client/api/types";

interface Dimensions {
    height: number;
    width: number;
}

export interface CarouselItemProps {
    item: EventPlatformEntry;
    className?: string;
    currentCardsNumberInSlides: 2 | 3 | 4 | 5;
    minPossibleCardsNumberInSlides: 2 | 3 | 4 | 5;
    // render the item without link to the entry (make it not clickable)
    noLink?: boolean;
    categoryId?: number;
    playlistId?: string;
    tabIndex?: number;
}


interface MediaItemProps {
    slide: number;
    className?: string;
    smallSize: Dimensions;
    mediumSize: Dimensions;
    largeSize: Dimensions;
    extraLargeSize: Dimensions;
}

const StyledItemLink = styled(ItemLink)({
    display: 'inline-block',
    width: 'auto',
    height: 'auto'
});

const StyledMediaItem = styled.div<MediaItemProps>(
    ({ theme, ...props }) => ({
        borderRadius: theme.kaltura.shape.roundness1,
        backgroundColor: theme.kaltura.palette.surfaces.paper,
        overflow: "hidden",
        "& .media-thumbnail__duration": {
            fontWeight: theme.kaltura.typography.fontWeightBold,
            backgroundColor: alpha(theme.palette.common.black, 0.6),
            borderRadius: theme.kaltura.shape.roundness1,
            fontSize: theme.kaltura.typography.fontSize,
            padding: "4px 5px",
        },
        // xs, sm
        width: props.smallSize.width,
        height: props.smallSize.height + 64,
        [StyledMediaImage as never]: {
            width: "inherit",
            height: props.smallSize.height,
        },
        // md +
        [theme.breakpoints.up("md")]: {
            width: props.mediumSize.width,
            height: props.mediumSize.height + 64,
            [StyledMediaImage as never]: {
                width: "inherit",
                height: props.mediumSize.height,
            },

            ...(props.slide === 2 && {
                height: props.mediumSize.height + 92,
            }),
        },
        // lg +
        [theme.breakpoints.up("lg")]: {
            width: props.largeSize.width,
            height: props.largeSize.height + 64,
            [StyledMediaImage as never]: {
                width: "inherit",
                height: props.largeSize.height,
            },
            ...(props.slide === 2 && {
                height: props.largeSize.height + 92,
            }),
        },
        // xl
        [theme.breakpoints.up("xl")]: {
            width: props.extraLargeSize.width,
            height: props.extraLargeSize.height + 64,
            [StyledMediaImage as never]: {
                width: "inherit",
                height: props.extraLargeSize.height,
            },
            ...(props.slide === 2 && {
                height: props.extraLargeSize.height + 92,
                [Data as never]: {
                    marginTop: 4,
                },
                [ItemDetailsWrapper as never]: {
                    padding: theme.spacing(2),
                },
            }),
        },
    })
);

/** Component to display media item in playlist carousel
 */
export const CarouselItem: React.FC<CarouselItemProps> = ({
    item: entry,
    categoryId,
    className = "",
    noLink = false,
    currentCardsNumberInSlides,
    minPossibleCardsNumberInSlides,
    playlistId,
    tabIndex
}) => {
    const config = useConfig();
    const { timeDisplay, dateFormats } = config.application;

    let linkUrl = useEntryUrl({entry, categoryId, playlistId});
    if (noLink) {
        linkUrl = undefined;
    }

    const openInNewTab = linkUrl && entry.type === KalturaEntryType.document && !config.application.isKAF;

    let ariaLabelText = translate("watch %1", [entry.name]);
    if (entry.duration) {
        ariaLabelText = translate("watch %1, duration: %2", [entry.name, durationToHuman(entry.duration)]);
    }

    const thumbnailAltText = translate("thumbnail for %1", [entry.name]);

    let liveStatus = WebcastStatus.noScheduling;
    let formattedTime = "";
    const timeFormat =
        timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM

    if (entry.schedulingData) {
        // date | 09:00AM – 10:00AM
        const formattedStartTime = translateTime(
            entry.schedulingData.start.timestamp,
            `${dateFormats.shortDate} | ${timeFormat} `
        ).toUpperCase();
        const formattedEndTime = translateTime(
            entry.schedulingData.end.timestamp,
            timeFormat
        );
        formattedTime = formattedStartTime + " - " + formattedEndTime;

        liveStatus = WebcastHelper.getLiveStatus(entry.schedulingData);
    }

    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

    // calculate thumbnail dimensions
    const {smallSize, mediumSize, largeSize, extraLargeSize} = useCarouselItemThumbnailSize(minPossibleCardsNumberInSlides);

    const sendButtonAnalytics = useButtonAnalytics();

    const sendAnalytics = () => {
        sendButtonAnalytics("Playlist - Media Thumbnail", ButtonClickAnalyticsType.THUMBNAIL);
    };

    return (
        <StyledItemLink
            href={linkUrl}
            aria-label={ariaLabelText}
            tabIndex={tabIndex}
            target={openInNewTab ? "_blank" : ""}>
            <StyledMediaItem
                className={`kms-ds-carousel-item-inner ${className}`}
                slide={minPossibleCardsNumberInSlides}
                smallSize={smallSize}
                mediumSize={mediumSize}
                largeSize={largeSize}
                extraLargeSize={extraLargeSize}
            >
                <StyledMediaImage className="kms-ds-carousel-item-image">
                        <Thumbnail
                            thumbnailUrl={formatEntryThumbnailUrl(
                                entry.thumbnailUrl,
                                "379",
                                "213"
                            )}
                            duration={entry.duration}
                            altText={thumbnailAltText}
                            onClick={sendAnalytics}
                            openInNewTab={openInNewTab}
                            tabIndex={-1}
                        />
                    </StyledMediaImage>
                    <ItemDetailsWrapper className="kms-ds-carousel-item-text-wrapper">
                        <StyledTruncatedName
                            lines={
                                liveStatus === WebcastStatus.live ||
                                currentCardsNumberInSlides === 2
                                    ? 1
                                    : 2
                            }
                            showMore={false}
                            className="kms-ds-carousel-item-title"
                        >
                            {entry.name}
                        </StyledTruncatedName>
                        {liveStatus === WebcastStatus.live && (
                            <DataContainer className={"chromatic-ignore kms-ds-carousel-item-description"}>
                                <Data>{formattedTime}</Data>
                                <StatusTag>{translate("Live")}</StatusTag>
                            </DataContainer>
                        )}
                        {minPossibleCardsNumberInSlides === 2 &&
                            liveStatus !== WebcastStatus.live && (
                                <StyledTruncatedDescription
                                    lines={isTabletOrMobile ? 3 : 2}
                                    showMore={false}
                                    className="kms-ds-carousel-item-description"
                                >
                                    {stripTags(entry.description)}
                                </StyledTruncatedDescription>
                            )}
                    </ItemDetailsWrapper>
            </StyledMediaItem>
        </StyledItemLink>

    );
};
