import { lazy } from "react";
import { getDsLayoutComponentRenderer } from "@mediaspace/features/layout";

const UploadMediaContainer = lazy(
    () => import("@mediaspace/features/upload-media")
);

const render = getDsLayoutComponentRenderer(UploadMediaContainer);

export { render, UploadMediaContainer };
