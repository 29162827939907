import styled from "@emotion/styled";
import { CrossAppUnpublishRequestData, PublishSaveRequestData, PublishStatus } from "@mediaspace/shared/types";
import { Modal, ModalActions, modalClasses, ModalContent, modalContentClasses } from "@kaltura/ds-react-components";
import { SaveType } from "./../../utils";

export interface ModalProps {
    entryIds: string[];
    publishStatus: PublishStatus;
    onClose?: () => void;
    /**
     * save publish status handler
     * private, unlisted, published save
     * or cross application unpublish save
     */
    onSubmit?: (data: PublishSaveRequestData | CrossAppUnpublishRequestData, saveType?: SaveType) => void;
}

export const StyledModal = styled(Modal)(({ theme }) => ({
    [`& .${modalClasses.paper}`]: {
        minWidth: 600,
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
        }
    },
}));

export const StyledFixedHeightModal = styled(StyledModal)(({ theme }) => ({
    [`& .${modalClasses.paper}`]: {
        height: "85vh",
        [theme.breakpoints.down("sm")]: {
            height: "100%",
        }
    },
}));

export const StyledModalContent = styled(ModalContent)(({ theme }) => ({
    [`&.${modalContentClasses.root}`]: {
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }
    }
}));

export const StyledModalActions = styled(ModalActions)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

export const StyledModalDescription = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2),
}))
