import { forwardRef, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel } from "./CommonStyles";
import { MenuItem, Select } from "@kaltura/ds-react-components";
import { LabelledValue } from "@mediaspace/shared/types";

export interface SelectFieldProps {
    id: string;
    label: string;
    description?: string;
    value?: string;
    required?: boolean;
    options: LabelledValue[];
}

/**
 * date field for edit entry form
 */
const SelectField = forwardRef<any, SelectFieldProps>(({
    id, label, description, value, options: opts, required = false
                                                           }, ref) => {
    const { control } = useFormContext();

    const options = useMemo(() => opts.map(opt => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>), [opts]);

    return (
        <Container>
            <StyledFormLabel
                htmlFor={id}
                text={label}
                description={description}
                required={required}
            />
            <Controller
                control={control}
                name={id as 'dummy'}
                defaultValue={value ?? ""}
                rules={{ required: required }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                        id={id}
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                    >
                        {options}
                    </Select>
                )}
            />
        </Container>
    );
});

export default SelectField;
