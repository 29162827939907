import React from "react";
import styled from "@emotion/styled";
import EventPresenter from "../event-presenter/EventPresenter";
import { eventPresenterClasses } from "../event-presenter/eventPresenterClasses";
import { KmsTypePresenter } from "@mediaspace/shared/types";

type Props = {
    presenters: KmsTypePresenter[];
};

const StyledEventPresentersListNoPopover = styled("div")(
    ({ theme }) => ({
        display: "inline-flex",
        maxWidth: "100%",
        flexDirection: "column",
        gap: theme.spacing(1),
        [`& .${eventPresenterClasses.root}`]: {
            [theme.breakpoints.up("sm")]: {
                maxWidth: "100%",
            },
        },
        [`& .${eventPresenterClasses.details}`]: {
            width: "100%",
        },
        [`& .${eventPresenterClasses.name}`]: {
            color: theme.kaltura.palette.tone1,
        },
    })
);

/**
 * Event List Item Presenter List without popover - to be used in modals and popovers
 */
export const EventPresentersListNoPopover: React.FC<Props> = ({
    presenters,
}) => {
    return (
        <StyledEventPresentersListNoPopover>
            {presenters.map((presenter: KmsTypePresenter) => {
                return (
                    <EventPresenter
                        key={"presenter" + presenter.id}
                        data={presenter}
                    />
                );
            })}
        </StyledEventPresentersListNoPopover>
    );
};

export default EventPresentersListNoPopover;
