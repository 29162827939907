import {KeyboardEvent, useEffect, useRef, useState} from "react";
import ToggleMenuButton from "./ToggleMenuButton/ToggleMenuButton";
import styled from "@emotion/styled";

interface ContainerDivProps {
    isSidebarOpen: boolean;
}

const ContainerDiv = styled("div")<ContainerDivProps>(
    ({ theme, isSidebarOpen }) => ({
        boxSizing: "border-box",
        display: "flex",
        alignItems: "flex-start",
        ...(isSidebarOpen && {
            ".kms-ds-sidebar-menu-button-root": {
                [theme.breakpoints.up("md")]: {
                    // TODO: uncomment after moving top links to the vertical menu when there's no enough space for it
                    // marginLeft: 320,
                },
            },
        }),
    })
);

/**
 * Navigation Sidebar Menu wrapper component - this components contains only the
 * header menu toggle sidebar button, while the menu itself is being loaded separately in the
 * floating layer, in order to be located on top of the bottom CnC panel (on mobile), which is
 * also located in the floating layer.
 */
const SidebarMenu = () => {

    const [openSidebar, setOpenSidebar] = useState<boolean>(false);

    const menuRef = useRef<HTMLDivElement>(null);

    const handleOpenSidebar = () => {
        setOpenSidebar(true);
        document.dispatchEvent(new Event('onOpenSidebar'));
    };

    const handleFocusOpenMenuButton = () => {
        const openMenuButton = menuRef.current?.firstChild as HTMLElement;
        openMenuButton?.focus();
    };

    const handleCloseSidebar = () => {
        setOpenSidebar(false);
        handleFocusOpenMenuButton();
    }

    useEffect(() => {
        document.addEventListener('onCloseSidebar', handleCloseSidebar);
        return () => {
            document.removeEventListener('onCloseSidebar', handleCloseSidebar)
        };
    }, []);

    /*
     * Handle Menu button's tabbing events
     */
    const handleKeyDownMenuButton = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === "Enter" && !openSidebar) {
            e.preventDefault();
            document.dispatchEvent(new Event('onOpenSidebar'));
        }
    };

    return (
        <ContainerDiv ref={menuRef} isSidebarOpen={openSidebar} className="kms-ds-sidebar">
            <ToggleMenuButton
                classes={{ root: "kms-ds-sidebar-menu-button-root" }}
                isSidebarOpen={openSidebar}
                handleClickOpenSidebar={handleOpenSidebar}
                handleKeyDownMenuButton={handleKeyDownMenuButton}
            />
        </ContainerDiv>
    );
};

export default SidebarMenu;
