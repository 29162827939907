import { FunctionComponent, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { noop, translate } from "@mediaspace/shared/utils";
import SubmitButton from "./actions/Submit";
import CancelButton from "./actions/Cancel";
import { Field } from "./FieldsFactory";
import { ModalData, EditFormElement, EntryMetadataStructure } from "@mediaspace/shared/upload";
import ConfirmationModal from "./../confirmation-modal/ConfirmationModal";
import {
    StyledActionsContainer,
    StyledForm
} from "./StyleComponents";

export type FormData = {
    /**
     * related file token id
     */
    fileTokenId: string;

    /**
     * comma separated string of entry ids
     */
    entryIds: string;
} & EntryMetadataStructure;

export interface EditFormProps {
    formData: FormData;
    fieldsStructure: EditFormElement[];
    shouldClose: boolean;
    onSubmit: (data: Record<string, unknown>) => Promise<{success: boolean, error?: string}>;
    onClose: (tokenId: string) => void;
}

/**
 * Entry edit form
 */
const EditForm: FunctionComponent<EditFormProps> = ({
    formData,
    fieldsStructure,
    shouldClose,
    onSubmit,
    onClose,
}) => {
    const [modalData, setModalData] = useState<ModalData>();
    const [processing, setProcessing] = useState<boolean>(false);

    const useFormHookMethods = useForm({
        mode: "onChange",
        defaultValues: formData
    });


    // reset all local changes
    const handleCancel = () => {
        useFormHookMethods.reset(formData);
        onClose(formData.fileTokenId);
    };

    const handleClose = () => {
        // useFormHookMethods.formState.isDirty compares current values to default values,
        // so it always returns true because we only pass default values for name, description, tags.
        // instead, look at the list of dirty fields.
        if (Object.keys(useFormHookMethods.formState.dirtyFields).length !== 0) {
            // populate the `are-you-sure` modal:
            setModalData({
                yesCallback: () => {
                    if (!useFormHookMethods.formState.isValid) {
                        setModalData(undefined);
                        return;
                    }
                    // only if form is valid
                    handleSubmit(useFormHookMethods.getValues());
                    onClose(formData.fileTokenId);
                },
                yesButtonLabel: translate("Save Changes"),
                title: translate("Unsaved Changes"),
                text: translate(
                    "Changes aren't saved. Are you sure you want to leave without saving them?"
                )
            });
        } else {
            handleCancel();
        }
    };

    // save the values
    const handleSubmit = (values: FormData) => {
        setProcessing(true);
        const { fileTokenId, entryIds, ...valuesToSubmit } = values;
        onSubmit({ entryids: entryIds.split(","), data: valuesToSubmit }).then(() => {
            setProcessing(false);
            onClose(formData.fileTokenId);
        });
    };

    useEffect(() => {
        if (shouldClose) {
            handleClose();
        }
    }, [shouldClose]);

    return (
        <FormProvider {...useFormHookMethods}>
            <StyledForm onSubmit={useFormHookMethods.handleSubmit(handleSubmit)}>
                {fieldsStructure.map((field) =>
                    Field(field, formData)
                )}
                <StyledActionsContainer>
                    <CancelButton
                        key={"cancel"}
                        onClick={handleCancel}
                        disabled={processing}
                    />
                    <SubmitButton
                        key={"submit"}
                        loading={processing}
                        disabled={Object.keys(useFormHookMethods.formState.dirtyFields).length === 0 || processing}
                        onClick={noop}
                    />
                </StyledActionsContainer>
            </StyledForm>
            <ConfirmationModal
                open={!!modalData}
                noButtonLabel={translate("Leave")}
                noCallback={() => {
                    setModalData(undefined);
                    handleCancel();
                }}
                yesButtonLabel={modalData?.yesButtonLabel}
                yesCallback={modalData?.yesCallback || noop}
                title={modalData?.title}
                text={modalData?.text}
            />
        </FormProvider>
    );
};

export default EditForm;
