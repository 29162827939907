import {
    generateUtilityClass,
    generateUtilityClasses,
} from "@mediaspace/shared/styled";

export function getHeaderLogoClass(slot: string) {
    return generateUtilityClass("kms-ds-header-logo", slot);
}

export interface HeaderLogoClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the link element */
    link: string;
    /**  styles applied to the image element */
    image: string;
    /**  styles applied to the title element */
    title: string;
}

export type HeaderLogoClassKey = keyof HeaderLogoClasses;

export const headerLogoClasses = generateUtilityClasses("kms-ds-header-logo", [
    "root",
    "link",
    "image",
    "title",
]);

export default headerLogoClasses;
