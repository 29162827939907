import { useMemo, useState } from "react";

/**
 * Shift an array of items with a time-based offset.
 * It's useful for items of carousel-like components that need to render different items on each page load.
 * @param array - items to process
 * @param shiftIntervalMs - shift interval in milliseconds
 * @param limit - max number of items to return (leave undefined to keep all items)
 * @param now - for Storybook only! substitute the current time by a constant to have consistent Chromatic snapshots
 */
export const useTimeShiftedArray = <T>(
    array: T[],
    shiftIntervalMs: number,
    limit?: number,
    now?: number,
): T[] => {
    // The timestamp when the component was rendered the first time.
    // Could be overridden by the "now" prop for Storybook to have consistent Chromatic snapshots.
    const [firstRenderTime] = useState(() => now ?? Date.now());

    return useMemo(() => {
        if (!array.length) {
            return array;
        }
        const shiftOffset = Math.floor(firstRenderTime / shiftIntervalMs) % array.length;
        const loopedArray = [
            ...array.slice(shiftOffset),
            ...array.slice(0, shiftOffset),
        ];
        return limit ? loopedArray.slice(0, limit) : loopedArray;
    }, [array, firstRenderTime, shiftIntervalMs, limit]);
};
