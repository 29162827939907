import styled from "@emotion/styled";
import {
    ListItem as MuiListItem,
    ListItemProps as MuiListItemProps,
} from "@mui/material";

export interface ListItemProps extends MuiListItemProps {
    href: string;  // overcome a missing prop that is supported in MUI but not in typings
};

const StyledListItem = styled(MuiListItem)(({ theme }) => ({}));

/**
 * a KUIK wrapper for Mui's ListItem component
 */
export function ListItem(props: ListItemProps) {

    return (
        <StyledListItem {...props} />
    );
}

export default ListItem;
