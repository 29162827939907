import styled from "@emotion/styled";
import {ModalContent, ModalActions, Typography} from "@kaltura/ds-react-components";

export const StyledModalContent = styled(ModalContent)(({ theme }) => ({
    width: theme.typography.pxToRem(584),
    textAlign: "center",
    borderBottom: `1px solid ${theme.kaltura.palette.tone4}`,
    padding: theme.spacing(3)
}));

export const StyledModalActions = styled(ModalActions)(({ theme }) => ({
    padding: theme.spacing(2)
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3)
}));
