/**
 *
 */
import { baseUrl, getDataFromKms } from "./kms";

export default class LiveMediaHelper {
    static pollStream(streamUrl: string): Promise<boolean> {
        if (!streamUrl) {
            return Promise.reject("invalid URL");
        }
        const myRequest = new Request(streamUrl);

        return fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return Promise.resolve(response.ok);
        });
    }

    static pollKmsIsLive(entryId: string): Promise<boolean> {
        if (!entryId) {
            return Promise.reject("missing entry id");
        }
        const url = baseUrl + "/kwebcast/lean-entry/islive/id/" + entryId;

        return new Promise((resolve) => {
            getDataFromKms(url, {}, (result: {isLive: boolean}) => {
                resolve(result.isLive)
            }, false);
        });
    }
}
