import { FunctionComponent, KeyboardEvent, RefObject, useState } from "react";
import clsx from "clsx";
import ReactHtmlParser from "@mediaspace/shared/ui/html-react-parser";
import { TimeDisplay, WebcastEntry } from "@mediaspace/shared/types";
import {
    encodeUnicode,
    formatEntryThumbnailUrl,
    stripTags,
    translate,
    translateTime,
    useMediaQuery,
    WebcastHelper,
    WebcastStatus,
} from "@mediaspace/shared/utils";
import { useConfig } from "@mediaspace/shared/data-kms-config";
import { useButtonAnalytics, useEntryUrl } from "@mediaspace/hooks";
import { EventListItemModal } from "../event-list-item-modal/EventListItemModal";
import { EventPresenterLargeList } from "@mediaspace/event-platform/event-presenter";
import { useTheme } from "@mediaspace/shared/styled";
import {
    Container,
    Description,
    EntryLink,
    Name,
    SchedulingContainer,
    SchedulingLine,
    StatusLive,
    StyledActionSection,
    StyledEventListItem,
    Tags,
    Thumbnail,
} from "./styled-components";
import { getCallToAction } from "./getCallToAction";
import { ButtonClickAnalyticsType } from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import { TruncateWithTooltip } from "@mediaspace/shared/ui";

export interface EventListItemProps {
    className?: string;
    data: WebcastEntry /** entry to show */;
    categoryId?: number /** when entry is viewed in the context of a category */;
    vodCallback: (
        entryId: string,
        entryName: string,
        categoryId?: number,
    ) => void /** "watch vod" action button callback */;
    liveCallback: (
        entryId: string,
        entryName: string,
        categoryId?: number
    ) => void /** "join live" action button callback */;
    scheduledCallback: (
        entryId: string,
        calendarType: "google" | "outlook",
        categoryId?: number,
    ) => void /** "add to calendar" action button callback */;
    entryLink?: string /** link to entry page, with or without category context */;
    updateMyAgendaStorage?: (sessions: WebcastEntry[]) => void;
    getInMyAgenda?: (session: WebcastEntry) => boolean;
    disabledMode?: boolean;
    forwardedRef?: RefObject<HTMLAnchorElement>;
    inWatchlist?: boolean;
    analyticsValue?: string;
    onToggleWatchList?: (id: string, add: boolean) => void;
}


/**
 * Event Session Item, mostly as part of a list of event sessions
 * Displays scheduled-entry data, including scheduling info.
 */
export const EventListItem: FunctionComponent<EventListItemProps> = ({
    className = "",
    data,
    vodCallback,
    liveCallback,
    scheduledCallback,
    entryLink,
    updateMyAgendaStorage, // update state in my agenda tab for disappearing entries
    inWatchlist,
    disabledMode,
    forwardedRef,
    analyticsValue,
    onToggleWatchList,
    categoryId,
}) => {
    const context = useConfig();
    const { timeDisplay, dateFormats } = context.application;

    const showThumb = context.eventPlatform.eventListItem.showThumbnail;

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

    //January 23, 2020 9:00 AM - 9:30 AM EST /12 hours || 9:00 - 09:30 EST /24 hours
    const timeFormat =
        timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM
    const startTimeFormat = timeFormat;
    const endTimeFormat = `${timeFormat} z`;
    const formattedStartTime = translateTime(
        data.schedulingData.start.timestamp,
        startTimeFormat
    );
    const formattedEndTime = translateTime(
        data.schedulingData.end.timestamp,
        endTimeFormat
    );

    const sendButtonAnalytics = useButtonAnalytics();

    const formattedTime = formattedStartTime + " - " + formattedEndTime;
    const formattedDate = translateTime(data.schedulingData.start.timestamp, dateFormats.shortDate);
    const status = WebcastHelper.getLiveStatus(data.schedulingData);
    const callToActionButton = getCallToAction(
        status,
        data.id,
        data,
        vodCallback,
        liveCallback,
        scheduledCallback,
        sendButtonAnalytics,
        disabledMode,
        categoryId,
    );

    const [showReadMoreModal, setShowReadMoreModal] = useState(false);

    // disable title click by tab in disabledMode
    const handleDisabledMode = (event: KeyboardEvent) => {
        if (disabledMode && event.key !== "Tab") {
            event.preventDefault();
        }
    };

    entryLink = useEntryUrl({ entry: data, entryUrl: entryLink });

    const handleShowMore = (action: string) => {
        setShowReadMoreModal(true);
        sendButtonAnalytics(action, ButtonClickAnalyticsType.LAUNCH);
    }

    return (
        <StyledEventListItem
            className={clsx("kms-ds-event-list-item", className)}
            data-analytics={encodeUnicode(data.name)}
        >
            <SchedulingContainer className={"kms-ds-event-list-item-date chromatic-ignore"}>
                <SchedulingLine variant={"body2Highlight"} content={"time"} className={"kms-ds-event-list-item-date-time"}>
                    {formattedTime}
                </SchedulingLine>
                <SchedulingLine variant={"body2Highlight"} content={"date"} className={"kms-ds-event-list-item-date-date"}>
                    {formattedDate}
                </SchedulingLine>
                {status === WebcastStatus.live && (
                    <StatusLive className={"kms-ds-event-list-item-status"}>
                        {translate("Live")}
                    </StatusLive>
                )}
            </SchedulingContainer>
            <Container className="kms-ds-event-list-item-content">
                <EntryLink
                    className={"kms-ds-event-list-item-title"}
                    href={entryLink}
                    disabled={disabledMode}
                    ref={forwardedRef}
                    onKeyDown={handleDisabledMode}
                    onClick={() => sendButtonAnalytics("Session card - Name", ButtonClickAnalyticsType.LINK)}
                >
                    <TruncateWithTooltip text={data.name} tooltipPlacement={"top"}>
                        <Name variant={"h5"}>{data.name}</Name>
                    </TruncateWithTooltip>
                </EntryLink>
                <Tags
                    className="kms-ds-event-list-item-tags"
                    tags={data.tags}
                />
                <Description
                    className="kms-ds-event-list-item-description"
                    lines={2}
                    showMore={false}
                    preventStateUpdate={true}
                >
                    {/* we use ReactHtmlParser here to decode html entities */}
                    <>{ReactHtmlParser(stripTags(data.description))}</>
                </Description>


                {data.presenters && (
                    <EventPresenterLargeList
                        presenters={data.presenters}
                        className="kms-ds-event-list-item-presenters"
                        onShowMore={() => handleShowMore("Session card - Speakers more button")}
                    />
                )}

                <EventListItemModal
                    data={data}
                    show={showReadMoreModal}
                    onHide={() => setShowReadMoreModal(false)}
                    startDate={data.schedulingData.start.timestamp}
                    endDate={data.schedulingData.end.timestamp}
                    startDateFormat={startTimeFormat}
                    endDateFormat={endTimeFormat}
                    thumbnailUrl={formatEntryThumbnailUrl(
                        data.thumbnailUrl,
                        "auto",
                        "auto"
                    )}
                    imageAltText={translate("%1 session image", [data.name])}
                    isLive={status === WebcastStatus.live}
                    callToActionButton={callToActionButton}
                    updateMyAgendaStorage={updateMyAgendaStorage}
                    inWatchlist={inWatchlist}
                    onToggleWatchList={onToggleWatchList}                    
                />
                <StyledActionSection
                    className="kms-ds-event-list-item-actions"
                    data={data}
                    callToActionButton={callToActionButton}
                    onShowMore={() => handleShowMore("Session card - more info button")}
                    inWatchlist={inWatchlist}
                    onToggleWatchList={onToggleWatchList}
                />
            </Container>
            {showThumb && isLargeScreen && (
                <a
                    href={entryLink}
                    style={disabledMode ? { pointerEvents: "none" } : {}}
                    onKeyDown={handleDisabledMode}
                    className="kms-ds-event-list-item-image-wrapper"
                    onClick={() => sendButtonAnalytics(
                        analyticsValue ?? "Session card - Thumbnail",
                        ButtonClickAnalyticsType.THUMBNAIL
                    )}
                >
                    <Thumbnail
                        src={data.thumbnailUrl}
                        alt={data.name}
                        className="kms-ds-event-list-item-image"
                    />
                </a>
            )}
        </StyledEventListItem>
    );
};

export default EventListItem;
