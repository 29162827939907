import { useState } from "react";
import { Button } from "@kaltura/ds-react-components";
import { Like24Icon } from "@kaltura/ds-react-icons";
import {baseUrl, postKmsData, translatePlural} from "@mediaspace/shared/utils";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import {useButtonAnalytics} from "@mediaspace/hooks";

export interface LikeProps {
    likesCount: number;
    isLiked: boolean;
    onToggle: (like: boolean) => void | Promise<void>;
    processing?: boolean;
}

// TODO: get better title from Ido
export const Like = ({ likesCount, isLiked, onToggle, processing }: LikeProps) => <Button
    variant={"borderless"}
    size={"medium"}
    color={"secondary"}
    disabled={processing}
    onClick={() => onToggle(!isLiked)}
    startIcon={<Like24Icon color={isLiked ? "primary" : "inherit"} />}
    title={translatePlural("%1 like", "%1 likes", [likesCount])}
>
    {likesCount}
</Button>;


export interface LikeContainerProps {
    likesCount: number;
    isLiked: boolean;
    entryId: string;
    categoryId?: string;
    playlistId?: string;
}

/**
 * like button container
 */
export const LikeContainer = ({likesCount: likesCountParam, isLiked: isLikedParam, entryId, categoryId, playlistId}: LikeContainerProps) => {
    const [likesCount, setLikesCount] = useState(likesCountParam);
    const [isLiked, setIsLiked] = useState(isLikedParam);
    const [processing, setProcessing] = useState(false);

    const sendButtonAnalytics = useButtonAnalytics();

    const onToggle = async (isLike: boolean) => {
        if (processing || isLike === isLiked) {
            return;
        }

        setIsLiked(isLike);
        // Change the likes count naively before getting up-to-date info from the server
        setLikesCount(likesCount + (isLike ? 1 : -1));

        setProcessing(true);
        // Update the like on the server, and get the updated likes count
        const url = baseUrl + "/default/media/like";
        const params = {'id': entryId, 'categoryid': categoryId, 'playlistid': playlistId, isLike};
        try {
            const {likes} = await postKmsData(url, params);
            setLikesCount(likes);
        } catch (e: unknown) {
            // TODO: visual indication of the error?
            console.error("Failed to like/unlike a media", e, {url, params});
            // Restore previous values on API failure
            setIsLiked(isLiked);
            setLikesCount(likesCount);
        }
        setProcessing(false);

        // send analytics
        sendButtonAnalytics(`Like - ${isLiked ? "On" : "Off"}`, ButtonClickAnalyticsType.TOGGLE);
    };

    return <Like
        likesCount={likesCount}
        isLiked={isLiked}
        onToggle={onToggle}
        processing={processing}
    />;
};
