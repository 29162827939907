import styled from "@emotion/styled";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { useState, useEffect, useRef } from "react";
import { Link } from "@kaltura/ds-react-components";
import { useConfig } from "@mediaspace/shared/data-kms-config";
import { translate } from "@mediaspace/shared/utils";
import {
    WebcastEntry,
    KmsTypeSchedulingTimeInterval,
} from "@mediaspace/shared/types";
import { EventListItem } from "../event-list-item/EventListItem";
import { AddToCalendarHelper, getEntryUrl } from "@mediaspace/shared/utils";

// add start time label to the session type
interface SchedulingData extends KmsTypeSchedulingTimeInterval {
    label: string;
}
export interface ScheduledSession extends WebcastEntry {
    schedulingData: SchedulingData; // override type to allow adding a label to the scheduling data
    inWatchlist?: boolean; // add to watchlist data
}
export interface EventListProps {
    className?: string;
    sessions?: ScheduledSession[];
    disabledMode?: boolean;
    hideFilterButton?: boolean;
    categoryId?: number;
    onToggleWatchList?: (id: string, add: boolean) => void;
}

const StyledPreviousButton = styled(Link)(
    ({ theme }) => ({
        fontSize: theme.typography.pxToRem(14),
        marginBottom: theme.spacing(2),
    })
);

const StyledNextButton = styled(Link)(
    ({ theme }) => ({
        fontSize: theme.typography.pxToRem(14),
    })
);

const StyledEventListDivider = styled("div")(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    margin: theme.spacing(4, 0, 2, 0),
}));

const StyledEventListItem = styled(EventListItem)(
    ({ theme }) => ({
        marginBottom: theme.spacing(2),
    })
);

/**
 * Agenda Event List.
 *
 * Shows the sessions grouped by date.
 * Displays scheduled-entry data, including scheduling info.
 */
export function EventList(props: EventListProps) {
    const INITIAL_PAGE_SIZE = 10;
    const PAGE_SIZE = 5;

    const { className, sessions = [], disabledMode = false, onToggleWatchList } = props;

    const [nowIndex, setNowIndex] = useState(0);
    const [prevIndex, setPrevIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(INITIAL_PAGE_SIZE);

    const focusRef = useRef<HTMLAnchorElement>(null);
    const lastAddedRef = useRef(0);

    const config = useConfig();
    const enableEntryTitles = config?.application?.enableEntryTitles;

    const goToEntryPage = (entryId: string, entryName: string | undefined) => {
        entryName = enableEntryTitles ? entryName : undefined;
        document.location.href = getEntryUrl(entryId, undefined, entryName);
    };

    const handleShowEarlierSessions = () => {
        setPrevIndex((index) => index + PAGE_SIZE);
    };
    const handleShowLaterSessions = () => {
        setNextIndex((index) => index + PAGE_SIZE);
    };

    // set focus on the first new child - if entries are added to the tail of the list
    // (earlier sessions are added to the head of the list)
    useEffect(() => {
        // check if the `next` index has been progressed
        if (nextIndex !== INITIAL_PAGE_SIZE &&
            lastAddedRef.current !== null &&
            lastAddedRef.current !== nextIndex &&
            focusRef.current !== null) {
            focusRef.current?.focus({preventScroll: true});
        }

        // focus was set - progress the last added ref
        lastAddedRef.current = nextIndex;
    }, [focusRef, lastAddedRef, nextIndex]);

    // sort sessions by start time
    const sortedSessionsByStartTime = sortBy(
        sessions,
        (session) => session.schedulingData.start.timestamp
    );

    // find the first active session index
    useEffect(() => {
        const now = Date.now() / 1000;
        setNowIndex(
            sortedSessionsByStartTime.findIndex(
                (session) => session.schedulingData.end.timestamp >= now
            )
        );
    }, [sortedSessionsByStartTime]);

    // calculate the sessions to show by prev/next indexes
    const startIndex = Math.max(nowIndex - prevIndex, 0);
    const endIndex = nowIndex + nextIndex;
    const sessionsToShow = sortedSessionsByStartTime.slice(
        startIndex,
        endIndex
    );

    // group shown sessions by their start-time label
    const sessionsByStartTime = groupBy(
        sessionsToShow,
        (session) => session.schedulingData.label
    );

    // get the session's dates labels
    const groupDates = Object.keys(sessionsByStartTime);

    // count sessions for setting focus
    let sessionIndex = 0;

    return (
        <div className={className}>
            {startIndex > 0 && (
                <StyledPreviousButton
                    component="button"
                    inText={false}
                    onClick={handleShowEarlierSessions}
                    className={"kms-ds-event-list-view-earlier"}
                >
                    {translate("View Earlier Sessions")}
                </StyledPreviousButton>
            )}

            {groupDates.map((label, index) => (
                <div key={label}>
                    {
                        // do not show the first date label
                        index > 0 && (
                            <StyledEventListDivider className="kms-ds-event-list-divider">
                                {label}
                            </StyledEventListDivider>
                        )
                    }
                    {sessionsByStartTime[label].map((entry) => {
                            return (
                                <StyledEventListItem
                                    forwardedRef={
                                        lastAddedRef.current === sessionIndex++
                                            ? focusRef
                                            : undefined
                                    }
                                    data={entry}
                                    key={`agenda-session_${entry.id}`}
                                    vodCallback={goToEntryPage}
                                    liveCallback={goToEntryPage}
                                    scheduledCallback={AddToCalendarHelper.getAddToCalendarAction(
                                        config
                                    )}
                                    entryLink={getEntryUrl(
                                        entry.id,
                                        undefined,
                                        enableEntryTitles
                                            ? entry.name
                                            : undefined
                                    )}
                                    inWatchlist={entry.inWatchlist}
                                    disabledMode={disabledMode}
                                    onToggleWatchList={onToggleWatchList}
                                />
                            );
                        })}
                </div>
            ))}

            {endIndex < sessions.length && (
                <StyledNextButton
                    component="button"
                    inText={false}
                    onClick={handleShowLaterSessions}
                    className="kms-ds-event-list-view-later"
                >
                    {translate("View Later Sessions")}
                </StyledNextButton>
            )}
        </div>
    );
}

export default EventList;
