import { FunctionComponent } from "react";
import { KmsTypePresenter } from "@mediaspace/shared/types";
import { translate } from "@mediaspace/shared/utils";
import { TruncatedLine } from "@mediaspace/shared/ui";
import { Initials } from "@kaltura/ds-react-components";
import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { composeClasses } from "@mediaspace/shared/styled";
import {
    EventPresenterClasses,
    getEventPresenterClass
} from "./eventPresenterClasses";
import clsx from "clsx";

export interface EventPresenterProps {
    className?: string;
    data: KmsTypePresenter /** data to show */;
    inPopover?: boolean;
    isTranslucent?: boolean;
    // Show only the thumbnails, no details
    compact?: boolean;
    classes?: Partial<EventPresenterClasses>;
}

const PresenterImage = styled("img")(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
    width: 36,
    height: 36
}));

const Presenter = styled("div")({
    display: "flex",
});
const Details = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
}));

const Name = styled(Typography, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "isTranslucent";
    }
})<{ isTranslucent?: boolean }>(
    ({ theme, isTranslucent }) => ({
        color: isTranslucent ? theme.kaltura.palette.translucent.main : theme.kaltura.palette.tone1,
        fontSize: theme.typography.pxToRem(theme.kaltura.typography.fontSize),
        fontWeight: theme.kaltura.typography.fontWeightBold,
        lineHeight: "normal",
        margin: 0,
        marginTop: 1,
    })
);

const Title = styled(Typography, {
    shouldForwardProp(propName: PropertyKey): boolean {
        return propName !== "isTranslucent" && propName !== "inPopover";
    }
})<{ isTranslucent?: boolean; inPopover?: boolean }>(
    ({ theme, isTranslucent, inPopover }) => ({
        color: isTranslucent ? theme.kaltura.palette.translucent.main : theme.kaltura.palette.tone2,
        fontSize: theme.typography.pxToRem(theme.kaltura.typography.fontSize),
        lineHeight: "normal",
        margin: 0,
        ...(inPopover && {
            color: theme.kaltura.palette.tone1
        }),
    })
);

const useUtilityClasses = ({ classes }: Partial<EventPresenterProps>) => {
    const slots = {
        root: ["root"],
        details: ["details"],
        name: ["name"],
        title: ["title"],
    };

    return composeClasses(slots, getEventPresenterClass, classes);
};

/**
 * Event Session Presenter, used in event list items
 */
export const EventPresenter: FunctionComponent<EventPresenterProps> = (props) => {
    const { data, className, isTranslucent, inPopover, compact } = props;
    const classes = useUtilityClasses(props);

    const componentType = inPopover ? "kms-ds-inPopover" : isTranslucent ? "kms-ds-translucent" : "kms-ds-default";

    return (
        <Presenter
            className={clsx(classes.root, componentType, className)}
        >
            {!!data.image && (
                <PresenterImage
                    className="kms-ds-event-presenter-thumbnail"
                    src={data.image}
                    alt={translate("Picture of Presenter")}
                />
            )}
            {!data.image && (
                <div className="kms-ds-event-presenter-thumbnail">
                    <Initials
                        className="kms-ds-event-presenter-thumbnail-initials"
                        size={"medium"}
                        fullName={data.name}
                        aria-hidden="true"
                    />
                </div>
            )}
            {!compact && <Details className={classes.details}>
                <Name className={classes.name} isTranslucent={isTranslucent}>
                    <TruncatedLine>
                        {data.name}
                    </TruncatedLine>
                </Name>

                <Title
                    className={classes.title}
                    paragraph={true}
                    isTranslucent={isTranslucent}
                    inPopover={inPopover}
                >
                    <TruncatedLine>
                        {data.title}
                    </TruncatedLine>
                </Title>

            </Details>}
        </Presenter>
    );
};

export default EventPresenter;
