import clamp from "lodash/clamp";
import styled from "@emotion/styled";
import { alpha, Skeleton, systemWidthSize } from "@mediaspace/shared/styled";
import { useCarouselItemThumbnailSize } from "@mediaspace/hooks";

interface Dimensions {
    height: number;
    width: number;
}

export interface CarouselItemSkeletonProps {
    minPossibleCardsNumberInSlides: 2 | 3 | 4 | 5;
}


interface MediaItemProps {
    slide: number;
    className?: string;
    smallSize: Dimensions;
    mediumSize: Dimensions;
    largeSize: Dimensions;
    extraLargeSize: Dimensions;
}


const StyledMediaItem = styled.div<MediaItemProps>(
    ({ theme, ...props }) => ({
        borderRadius: theme.kaltura.shape.roundness1,
        backgroundColor: theme.kaltura.palette.surfaces.paper,
        overflow: "hidden",
        "& .media-thumbnail__duration": {
            fontWeight: theme.kaltura.typography.fontWeightBold,
            backgroundColor: alpha(theme.palette.common.black, 0.6),
            borderRadius: theme.kaltura.shape.roundness1,
            fontSize: theme.kaltura.typography.fontSize,
            padding: "4px 5px",
        },
        // xs, sm
        width: props.smallSize.width,
        height: props.smallSize.height + 64,
        // md +
        [theme.breakpoints.up("md")]: {
            width: props.mediumSize.width,
            height: props.mediumSize.height + 64,
            ...(props.slide === 2 && {
                height: props.mediumSize.height + 92,
            }),
        },
        // lg +
        [theme.breakpoints.up("lg")]: {
            width: props.largeSize.width,
            height: props.largeSize.height + 64,
            ...(props.slide === 2 && {
                height: props.largeSize.height + 92,
            }),
        },
        // xl
        [theme.breakpoints.up("xl")]: {
            width: props.extraLargeSize.width,
            height: props.extraLargeSize.height + 64,
            ...(props.slide === 2 && {
                height: props.extraLargeSize.height + 92,
            }),
        },
    })
);

/**
 * skeleton for media item in playlist carousel
 */
export const CarouselItemSkeleton = ({ minPossibleCardsNumberInSlides }: CarouselItemSkeletonProps) => {

    // calculate thumbnail dimensions
    const {smallSize, mediumSize, largeSize, extraLargeSize} = useCarouselItemThumbnailSize(minPossibleCardsNumberInSlides);

    return (
            <StyledMediaItem
                className={"kms-ds-carousel-item-inner"}
                slide={minPossibleCardsNumberInSlides}
                smallSize={smallSize}
                mediumSize={mediumSize}
                largeSize={largeSize}
                extraLargeSize={extraLargeSize}
            >
                <Skeleton variant={"rectangular"} sx={{ width: "100%", height: "100%" }} />
            </StyledMediaItem>

    );
};
