import styled from "@emotion/styled";
import { ReactNode, useEffect, useState } from "react";
import throttle from "lodash/throttle";
import { HeaderColor } from "@mediaspace/shared/types/HeaderColor";
import { alpha } from "@mediaspace/shared/styled";

/**
 * The fixed height of the HeaderMenu component.
 * Could be safely used in position/size calculations.
 *
 * Please note that the header is absolutely positioned when "headerColor" prop equals to "transparent".
 * When DS layout is used, the color of the header could be retrieved from the config context: config.dsLayout.headerColor.
 *
 * See usage example in libs/features/layout/src/lib/HeroLayout.tsx
 */
export const headerHeight = 56;

export interface HeaderMenuContainerProps {
    sticky?: boolean;
    headerColor: HeaderColor; // Kms_Resource_Config::getConfiguration('header', 'headerStyle'); or transparent for hero pages
    scrollThreshold?: number;
    children?: ReactNode;
}

interface StyledHeaderProps {
    sticky?: boolean;
    scrollRatio?: number;
    headerColor?: string;
}

const StyledHeaderMenu = styled.div<StyledHeaderProps>(
    ({ theme, sticky, headerColor }) => ({
        ...(headerColor === "dark" && {
            backgroundColor: theme.kaltura.palette.surfaces.protection,
        }),
        ...(headerColor === "transparent" && {
            // The page may have side padding, so make sure that we have a "position: relative" parent for the "position: absolute" header (see "-navbar")
            position: "relative",
            width: "100%",
            zIndex: 1000,
        }),
        ...(sticky && {
            top: 0,
            position: "sticky",
            zIndex: 1000,
        }),
    })
);

const StyledNavbar = styled.div<StyledHeaderProps>(({ theme, sticky, scrollRatio, headerColor }) => ({
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    height: headerHeight,
    ...(headerColor === "transparent" && {
        position: "absolute",
        width: "100%",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))`,
        ...(sticky && scrollRatio && scrollRatio > 0 && {
            backgroundImage: `linear-gradient(
                to bottom,
                ${alpha(theme.kaltura.palette.surfaces.protection, Math.min(0.4 + scrollRatio, 1))},
                ${alpha(theme.kaltura.palette.surfaces.protection, scrollRatio)}
            )`,
        }),
    }),
}));

/**
 * Site Header Menu component (with navigation, user menu, locale menu..)
 */
export const HeaderMenuContainer = ({
    sticky,
    headerColor,
    scrollThreshold = 60,
    children,
}: HeaderMenuContainerProps) => {
    const [isPageScrolled, setIsPageScrolled] = useState(false);
    const [scrollRatio, setScrollRatio] = useState(0);

    const throttledHandler = throttle(
        () => {
            // for theming class name
            setIsPageScrolled(window.scrollY > scrollThreshold);
            // for sticky transparency
            setScrollRatio(Math.min(window.scrollY/scrollThreshold * 0.7,1));
        },
        300
    );

    // Added class is-scrolled, could be styled header in mediaspace theme
    useEffect(() => {
        window.addEventListener("scroll", throttledHandler);
        return () => {
            window.removeEventListener("scroll", throttledHandler);
            throttledHandler.cancel();
        };
    }, [throttledHandler]);

    return (
        <StyledHeaderMenu
            sticky={sticky}
            scrollRatio={scrollRatio}
            headerColor={headerColor}
            className={"kms-ds-header-menu"}
            role={"banner"}
        >
            <StyledNavbar
                sticky={sticky}
                scrollRatio={scrollRatio}
                headerColor={headerColor}
                className={
                    "kms-ds-header-menu-navbar" +
                    (isPageScrolled ? " is-scrolled" : "")
                }
            >
                {children}
            </StyledNavbar>
        </StyledHeaderMenu>
    );
};
