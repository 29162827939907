import { lazy } from "react";
import { getDsLayoutComponentRenderer } from "@mediaspace/features/layout";

const LeaderboardContainer = lazy(() => import("@mediaspace/features/leaderboard"));

const render = getDsLayoutComponentRenderer(LeaderboardContainer);

export {
    render,
    LeaderboardContainer,
};
