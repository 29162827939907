import { useEffect } from "react";
import raf from "raf";
import { useEventHandler } from "./useLastValueRef";

/**
 * custom hook to implement raf (request animation frame) call within function components.
 */
export function useRaf(
    callback: () => void | Promise<void>,
    disabled = false
) {
    const memoizedCallback = useEventHandler(callback);

    useEffect(
        () => {
            if (disabled) {
                return;
            }
            let stopped = false;
            let rafHandle = raf(async function onRaf() {
                try {
                    await memoizedCallback();
                } finally {
                    // Schedule the next animation frame,
                    // but only if the effect wasn't cancelled while waiting for the last async callback
                    if (!stopped) {
                        rafHandle = raf(onRaf);
                    }
                }
            });

            return () => {
                stopped = true;
                raf.cancel(rafHandle);
            };
        },
        [memoizedCallback, disabled]
    );
}
