import { useDateFormat } from "@mediaspace/hooks/useDateFormat";
import { ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { KmsTypeMediaSchedulingData } from "@mediaspace/shared/types";
import { Separator, Statistics } from "./StyleComponents";

/**
 * media title scheduling component
 */
export const MediaSchedule = (props: {
	schedulingData: KmsTypeMediaSchedulingData;
}) => {
	const { schedulingData } = props;
	const { from: startTime, to: endTime } = schedulingData;
	const {
		startDay,
		endDay,
		formattedScheduledStartTime,
		formattedScheduledEndTime,
    } = useDateFormat(startTime, endTime);

	return (
		<Statistics className="kms-ds-media-page-title-schedule">
			{startDay === endDay && (
				<>
					{startDay}
					<Separator />
					{formattedScheduledStartTime}&nbsp;-&nbsp;
					{formattedScheduledEndTime}
				</>
			)}
			{startDay !== endDay && (
				<>
					{`${startDay}, ${formattedScheduledStartTime}`}
					&nbsp;
					<ChevronRight16Icon />
					&nbsp;
					{`${endDay}, ${formattedScheduledEndTime}`}
				</>
			)}
		</Statistics>
	);
};
