import { alpha } from "@mediaspace/shared/styled";
import { Truncate } from "@mediaspace/shared/ui";
import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { KmsTypeAddNewMenuItem } from "@mediaspace/shared/types/addNew";

export interface AddNewIllustrationProps {
	item: KmsTypeAddNewMenuItem;
}

const StyledIllustrationContainer = styled("div")(
	({ theme }) => ({
		position: "absolute",
		top: 0,
		right: 0,
		height: "100%",
		width: "50%",
		boxSizing: "border-box",
		minHeight: 400,
		textAlign: "center",
		backgroundColor: alpha(theme.kaltura.palette.primary.main, 0.05),
		padding: theme.spacing(2),
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	})
);

const StyledStickyContainer = styled("div")(({ theme }) => ({
	top: "50%",
	position: "sticky",
	paddingTop: theme.spacing(2),
}));

const StyledIllustrationWrapper = styled("div")({
    height: "50%",
    position: "relative",
});

const StyledDataWrapper = styled("div")({
	height: "50%",
});

const StyledIllustration = styled("img")({
	width: 200,
	height: 200,
	position: "absolute",
	bottom: 0,
	left: 6,
});

const StyledIlustrationTitle = styled(Typography)({
    width: "100%",
    display: "block",
});

const StyledIlustrationDescription = styled(Truncate)(
	({ theme }) => ({
		fontSize: theme.typography.pxToRem(14),
		color: theme.kaltura.palette.tone2,
		width: "100%"
	})
);

/**
 * `Add New` menu illustration
 */
export function AddNewIllustration(props: AddNewIllustrationProps) {
	const { item } = props;

	return (
		<StyledIllustrationContainer role="presentation">
			<StyledStickyContainer>
				<StyledIllustrationWrapper>
					<StyledIllustration
						aria-hidden={true}
						src={item.illustrationUrl}
					/>
				</StyledIllustrationWrapper>
				<StyledDataWrapper>
					<StyledIlustrationTitle variant="button">
						{item.label}
					</StyledIlustrationTitle>
					<StyledIlustrationDescription
						aria-hidden={true}
						lines={7}
						showMore={false}
					>
						{item.description}
					</StyledIlustrationDescription>
				</StyledDataWrapper>
			</StyledStickyContainer>
		</StyledIllustrationContainer>
	);
}

export default AddNewIllustration;
