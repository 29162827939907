import { Richtext, RichTextProps } from "@kaltura/ds-react-richtext";
import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
    Container,
    StyledFormLabel
} from "./CommonStyles";

export interface RichtextFieldProps extends RichTextProps {
    id: string;
    label: string;
    description?: string;
    value?: string;
}

/**
 * richtext field for edit entry form
 */
const RichtextField = forwardRef<any, RichtextFieldProps>(({
    id, label, description, value, defaultValue, ...rest
                                                           }, ref) => {
    const { control } = useFormContext();

    return (
        <Container>
            <StyledFormLabel
                htmlFor={id}
                text={label}
                description={description}
            />
            <Controller
                control={control}
                name={id as 'dummy'}
                defaultValue={value}
                render={({
                             field: { onChange, onBlur, value, name, ref },
                             fieldState: { isTouched, isDirty, error },
                             formState,
                         }) => (
                    <Richtext
                        {...rest}
                        //TODO id={id} required for accessibility
                        value={value}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                )}
            />
        </Container>
    );
});

export default RichtextField;
