import {
    generateUtilityClass,
    generateUtilityClasses,
} from "@mediaspace/shared/styled";

export function getCarouselClass(slot: string) {
    return generateUtilityClass("kms-ds-carousel", slot);
}

export interface CarouselClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to carousel item element */
    item: string;
    /**  styles applied to the arrow element */
    arrow: string;
}

export type CarouselClassKey = keyof CarouselClasses;

export const carouselClasses = generateUtilityClasses("kms-ds-carousel", [
    "root",
    "item",
    "arrow",
]);

export default carouselClasses;
