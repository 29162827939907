const globalPseudoClassesMapping: Record<string, string> = {
    active: "kms-ds-active",
    checked: "kms-ds-checked",
    disabled: "kms-ds-disabled",
    error: "kms-ds-error",
    focused: "kms-ds-focused",
    focusVisible: "kms-ds-focusVisible",
    required: "kms-ds-required",
    expanded: "kms-ds-expanded",
    selected: "kms-ds-selected",
};

export function generateUtilityClass(
    componentName: string,
    slot: string
): string {
    const globalPseudoClass = globalPseudoClassesMapping[slot];
    return globalPseudoClass || `${componentName}-${slot}`;
}
