import ReactDOM from "react-dom";
import React, {ComponentType, Suspense} from "react";
import { ThemeProvider } from "@mediaspace/shared/styled";
import {Config, ConfigProvider} from "@mediaspace/shared/data-kms-config";
import type { LoginSiteHeaderProps } from "@mediaspace/login-site-header";

const LoginSiteHeader = React.lazy(() => import("@mediaspace/login-site-header"));

const Login = React.lazy(
    () =>
        import("@mediaspace/features/login/login/Login")
);

const ForgotPassword = React.lazy(
    () =>
        import("@mediaspace/features/login/forgot-password/ForgotPassword")
);

const PasswordExpired = React.lazy(
    () =>
        import("@mediaspace/features/login/password-expired/PasswordExpired")
);
const ResetPassword = React.lazy(
    () =>
        import("@mediaspace/features/login/reset-password/ResetPassword")
);

const SetPassword = React.lazy(
    () =>
        import("@mediaspace/features/login/set-password/SetPassword")
);

const LoginSelection = React.lazy(
    () =>
        import("@mediaspace/features/login/login-selection/LoginSelection")
);


/**
 * header is rendered independently in multiple pages, so it deserves its own "render" method
 * @param id
 * @param props
 */
const renderHeader = (
    id: string,
    props: LoginSiteHeaderProps & { context: Config }
) => {
    const { theming } = props.context;
    ReactDOM.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode}>
                    <LoginSiteHeader {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};

/**
 * this will be used to render other components/pages
 */
const render = (
    containerId: string,
    props: React.ComponentProps<any> & { context: Config, component: ComponentType<any> }
) => {

    const { context, component: Component, ...rest } = props;
    const { theming } = context;
    ReactDOM.render(
        <Suspense fallback={<div/>}>
            <ConfigProvider context={context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode}>
                    <Component {...rest} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(containerId)
    );
};

export {
    renderHeader,
    render,
    Login,
    ForgotPassword,
    PasswordExpired,
    ResetPassword,
    SetPassword,
    LoginSelection
};
