import { ComponentType, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mediaspace/shared/styled";
import {
    AutoComplete as AutoCompleteComponent,
    Initials as InitialsComponent,
    InitialsProps,
    Loader as LoaderComponent,
} from "@kaltura/ds-react-components";

export { loadPlayerLib } from "@mediaspace/shared/utils";

/*
 * This package ports DS components to be used by the legacy code (as a preparation to React migration).
 *
 * Since DS and legacy code are going to use different versions of node, React and other libraries,
 * DS components cannot be imported in a usual way and used directly in the legacy code.
 * That's why the legacy code initializes a new React root element on its side
 * and uses the functions below to render the DS components in it.
 *
 * See components/DsPort/DsComponents for usage.
 */

const KafAddNewMenuComponent = lazy(() => import("@mediaspace/kaf-add-new-menu"));

const getComponentRenderer = <T,>(Component: ComponentType<T>) => (rootElement: HTMLDivElement) => {
    const WrappedComponent = (props: T) => <Suspense fallback={<div/>}>
        <ThemeProvider scoped={true}>
            <Component {...props} />
        </ThemeProvider>
    </Suspense>;

    let renderedOnce = false;

    return {
        render: (props: T) => {
            if (!renderedOnce) {
                ReactDOM.render(<WrappedComponent {...props}/>, rootElement);
            } else {
                // Re-render the component while preserving the state and all other hooks
                ReactDOM.hydrate(<WrappedComponent {...props} />, rootElement);
            }
            renderedOnce = true;
        },
        dispose: () => {
            if (renderedOnce) {
                ReactDOM.unmountComponentAtNode(rootElement);
            }
            renderedOnce = false;
        },
    };
};

export const KafAddNewMenu = getComponentRenderer(KafAddNewMenuComponent);

export const AutoComplete = getComponentRenderer(AutoCompleteComponent);
export const Initials = getComponentRenderer<InitialsProps>(InitialsComponent);
export const Loader = getComponentRenderer(LoaderComponent);
