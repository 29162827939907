import StatusScreen from "@mediaspace/shared/ui/status-screen/StatusScreen";
import React from "react";
import { translate } from "@mediaspace/shared/utils";
import { ColoredErrorIllustration } from "@mediaspace/shared/ui/colored-error-illustration/ColoredErrorIllustration";

/**
 * Generate AI quiz error state
 */
export const AiQuizGenerateError = () => {
    return (
        <StatusScreen
            title={translate("Something went wrong")}
            text={translate(
                "Something went wrong during AI quiz creation process. Please try again later or contact support."
            )}
            image={<ColoredErrorIllustration />}
            contentWidth={640}
        />
    );
};
