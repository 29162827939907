/**
 * generates tab props in a uniform way
 * @param name tab name
 */
export const tabProps = (name: string) => {
    return {
        id: `tab-${name}`,
        'aria-controls': `tabpanel-${name}`,
        value: name,
    };
}
