import { Theme } from "@emotion/react";
import { CSSObject } from "@emotion/styled";

/**
 * object containing general HTML styles according to the Design-System.
 * should be used to apply DS-styles to user-generated texts that have HTML content,
 * like descriptions, etc.
 * Usage example:
 * <pre>
 * const StyledUserGeneratedHtmlText = styled("div")(({ theme }) => [
 *     generalHtmlStyles(theme),
 *     {
 *         // other styles
 *     },
 * ]);
 *
 * ....
 *
 * <StyledUserGeneratedHtmlText>{ReactHtmlParser(userInput)}</StyledUserGeneratedHtmlText>
 * </pre>
 */
export const generalHtmlStyles = (theme: Theme): CSSObject => ({
    "& h1": theme.typography.h1 as CSSObject,
    "& h2": theme.typography.h2 as CSSObject,
    "& h3": theme.typography.h3 as CSSObject,
    "& h4": theme.typography.h4 as CSSObject,
    "& h5": theme.typography.h5 as CSSObject,
    "& h6": theme.typography.h6 as CSSObject,
    "& a": {
        /** these values are copied from the ui-kit's Link component. */
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
        textDecorationColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.primary.dark,
        },
        "&:active": {
            color: theme.palette.primary.light,
        },
        "&:visited": {
            color: theme.palette.primary.main,
        },
    },
    "& img": {
        // Large images shouldn't blow up the container
        maxWidth: "100%",
        // Make sure that the image maintains aspect ratio when there are "width" and "height" attributes on the <img> tag,
        // and the max-width rule above changes the width
        height: "auto",
    },
});
