import styled from "@emotion/styled";
import { Button, IconButton } from "@kaltura/ds-react-components";

export const ActionItem = styled("div")(({ theme }) => ({
    pointerEvents: "all",
    gap: theme.spacing(1),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
}));

export const Left = styled("div")(({ theme }) => ({
    alignItems: "flex-start",
    display: "inline-flex",
    flex: "0 0 auto",
    gap: theme.spacing(1),
}));

export const Right = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "inline-flex",
    flex: "0 0 auto",
    gap: theme.spacing(1),
}));



// used on both ActionButton and ActionIconButton components
export const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 auto",
};

export const ActionButton = styled(Button)(buttonStyle);

export const ActionIconButton = styled(IconButton)(buttonStyle);


