import React, { RefObject } from "react";
import {styled, TextField} from "@mui/material";

export interface CopiedTextProps {
    text: string;
    className?: string;
    fullWidth?: boolean;
    multiline?: boolean;
    maxRows?: number;
    inputClasses?: any;
    textFieldId: string;
}

const StyledLabel = styled("label")(() => ({
    width: 0,
    height: 0,
    display: "inline-block",
    overflow: "hidden"
}));

/**
 * Copied Text Component - the input field to go with CopyButton Component, selected and focused by it.
 *
 * To use this component, pass it and CopyButton Component a ref object.
 *
 * Reference implementation can be seen in BulkInviteModalContent Component under inviteUsers feature
 */
const CopiedText = React.forwardRef((props: CopiedTextProps, ref: RefObject<HTMLTextAreaElement>) => {
    const { text, className, fullWidth = true, multiline = true, maxRows, inputClasses, textFieldId } = props;

    return (
        <>
            <StyledLabel htmlFor={textFieldId} />
            <TextField
                id={textFieldId}
                variant="standard"
                className={className + " kms-ds-copied-text"}
                inputRef={ref}
                value={text}
                maxRows={maxRows}
                multiline={multiline}
                fullWidth={fullWidth}
                InputProps={{disableUnderline: true, classes: inputClasses}}
            />
        </>
    );
});
export default CopiedText;
