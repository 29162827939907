import { useCallback, useRef } from "react";

/**
 * React hook that returns an object with constant reference that points to the last value of the given input
 * (to the value from the last render).
 * This reference could be used as a "fake" dependency in the cases when the value shouldn't be counted as dependency.
 *
 * Common usage - point to the last value of an event handler callback
 * or to the last value of a variable that needs to be accessed while handling an event, e.g.
 * ```
 * const MyComponent = ({onFooChange}: MyComponentProps) => {
 *     // ...
 *
 *     const onFooChangeRef = useEventHandlerRef(onFooChange);
 *     const barRef = useLastValueRef(bar);
 *
 *     // The changes in `onFooChange` and `bar` variables will not trigger the effect.
 *     // Only the changes in `foo`.
 *     useEffect(() => {
 *         onFooChangeRef.current(`Foo changed to ${foo}. Oh, and the current value of bar is ${barRef.current}`);
 *     }, [foo, onFooChangeRef, barRef]);
 *
 *     // ...
 * };
 *
 * // Note that the reference to the `onFooChange` handler is not memoized, so it will change each render.
 * // But it's fine because MyComponent wraps it into useEventHandlerRef.
 * const UsageExample = () => <MyComponent
 *   onFooChange={(value) => console.log(value)}
 * />;
 * ```
 *
 * See example in AutoHorizontalMenu.
 */
export const useLastValueRef = <T>(value: T) => {
  const ref = useRef(value);
  ref.current = value;
  return ref;
};

/**
 * React hook that returns an event handler callback with a constant reference
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEventHandler = <ArgsT extends any[], ReturnT>(handler?: (...args: ArgsT) => ReturnT) => {
    const ref = useLastValueRef(handler);

    return useCallback((...args: ArgsT) => ref.current?.(...args), [ref]);
};
