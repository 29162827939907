import {
    generateUtilityClass,
    generateUtilityClasses,
} from "@mediaspace/shared/styled";

export function getEventPresenterClass(slot: string) {
    return generateUtilityClass("kms-ds-event-presenter", slot);
}

export interface EventPresenterClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the details element */
    details: string;
    /**  styles applied to the name element */
    name: string;
    /**  styles applied to the title element */
    title: string;
}

export const eventPresenterClasses: EventPresenterClasses =
    generateUtilityClasses("kms-ds-event-presenter", ["root", "details", "name", "title"]);

export default eventPresenterClasses;
