import { KmsTypePublishCategory } from "@mediaspace/shared/types";
import { Tooltip, Typography } from "@kaltura/ds-react-components";
import styled from "@emotion/styled";
import { useContext } from "react";
import { CategoryBubble, CategoryBubbleType } from "../category-bubbles/CategoryBubble";
// noinspection ES6PreferShortImport
import { CategoryLinkAnalyticsContext } from "../CategoryLinkAnalyticsContext";

interface CategoryLink extends KmsTypePublishCategory {
    url?: string;
    label?: string;
}

export interface CategoriesListProps {
    categories: CategoryLink[];
    className?: string;
    title?: { label: string; color: string };
    onDelete?: (category: CategoryBubbleType) => void;
}

/**
 * CategoriesList Component
 * This component displays a list of categories, each with a tooltip and an optional title (pending in:/rejected in:).
 * - If a category has a URL or if the component includes an onDelete callback, it will be rendered as a bubble.
 * - In all other cases, categories will be rendered as simple, comma-separated names.
 */
export function CategoriesList({ className = "", categories, title, onDelete }: CategoriesListProps) {
    const { sendCategoryLinkAnalytics } = useContext(CategoryLinkAnalyticsContext);
    return (
        <>
            {!!categories.length && (
                <StyledCategories className={className}>
                    {title && (
                        <StyledTitle variant="body2Highlight" color={title.color}>
                            {title.label}
                        </StyledTitle>
                    )}

                    {categories.map((category, index) => {
                        const isBubble = category.url || onDelete;
                        return isBubble ? (
                            <CategoryBubble
                                key={index}
                                category={category}
                                onClick={category.url ? sendCategoryLinkAnalytics : null}
                                onDelete={onDelete}
                            />
                        ) : (
                            <Tooltip title={category.label} key={category.id}>
                                <StyledCategory variant="body2Highlight">
                                    {`${category.name}${index < categories.length - 1 ? "," : ""}`}
                                </StyledCategory>
                            </Tooltip>
                        );
                    })}
                </StyledCategories>
            )}
        </>
    );
}

const StyledCategories = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    rowGap: theme.spacing(1),
    alignItems: "center",
}));

const StyledCategory = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(0.5),
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(0.5),
    textWrap: "nowrap",
}));
