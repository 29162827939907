import React, { ElementType, ForwardedRef } from "react";
import styled from "@emotion/styled";
import {
	Button,
	IconButton,
	ButtonProps,
} from "@kaltura/ds-react-components";
import { useMediaQuery } from "@mediaspace/shared/utils";
import { useTheme } from "@mediaspace/shared/styled";

const StyledButton = styled(Button)({
    minWidth: "fit-content",
}) as unknown as typeof Button;

/**
 * `Add New` menu header button. or possibly a link, for logged-out users.
 */
export const AddNewMenuButton = React.forwardRef(
	<T extends ElementType>(props: ButtonProps<T> & { component?: T }, ref: ForwardedRef<any>) => {
		const { startIcon, ...rest } = props;
		const theme = useTheme();
		const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

		return (
			<>
				{smallScreen && (
					<IconButton {...rest} variant={"pill"} ref={ref}>
						{startIcon}
					</IconButton>
				)}
				{!smallScreen && (
					<StyledButton {...rest} kuid={"create-menu-add-new-link"} startIcon={startIcon} ref={ref} />
				)}
			</>
		);
	}
) as typeof Button;
export default AddNewMenuButton;
