import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { HeaderLogo, HeaderLogoProps } from "./HeaderLogo/HeaderLogo";
import { Search24Icon } from "@kaltura/ds-react-icons";
import { KmsTypeLinkLink } from "@mediaspace/shared/types/KmsTypeLinkLink";
import { Locale } from "@mediaspace/shared/types/Locale";
import { noop, translate, useMediaQuery } from "@mediaspace/shared/utils";
import { IconButton } from "@kaltura/ds-react-components";
import { LocaleMenu } from "./LocaleMenu/LocaleMenu";
import { SearchForm } from "@mediaspace/shared/ui";
import UserMenu from "./UserMenu/UserMenu";
import AddNewMenu from "./add-new-menu/AddNewMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { SidebarMenuItem } from "@mediaspace/shared/types/SidebarMenuItem";
import { KmsTypeAddNewMenuSection } from "@mediaspace/shared/types/addNew";
import HorizontalMenu, { AutoHorizontalMenu } from "./HorizontalMenu/HorizontalMenu";
import { useTheme } from "@mediaspace/shared/styled";
import { SidebarOpenButton } from "@kaltura/ds-react-layouts";
import { HeaderColor } from "@mediaspace/shared/types/HeaderColor";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";
import {BadgesTabProps} from "@mediaspace/features/user-badges/common/types";
import BadgesMenu from "@mediaspace/features/user-badges/header/BadgesMenu";
import { HeaderMenuContainer } from "./HeaderMenuContainer";

export enum MenuStyle {
    Horizontal = "Horizontal",
    Vertical = "Vertical",
}

export interface HeaderMenuProps {
    className?: string;
    sticky?: boolean;
    headerColor: HeaderColor; // Kms_Resource_Config::getConfiguration('header', 'headerStyle'); or transparent for hero pages
    logo: HeaderLogoProps;
    userMenuItems?: KmsTypeLinkLink[];
    searchUrl?: string; // full url of where search should be submitted (global search), leave it empty to hide the search bar
    locales?: Locale[];
    currentLocale?: Locale;
    showNav: boolean;
    scrollThreshold: number;
    disableScrollLock?: boolean;
    pages?: SidebarMenuItem[];
    topLinks?: SidebarMenuItem[];
    topLinksColor?: "translucent" | "primary";
    addNewItems?: KmsTypeAddNewMenuSection[];
    createAsLogin?: boolean;
    menuStyle?: MenuStyle; //Kms_Resource_Config::getConfiguration('navigation', 'navigationStyle');
    badgesMenuItems?: BadgesTabProps;
}

const StyledHeaderLogo = styled(HeaderLogo)(({ theme }) => ({
    // The following flex props are required to support text-only logo truncation:
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "auto",
    // we support limited text length, this is the large logo size which is larger
    minWidth: "auto",
    maxWidth: 343,

    margin: theme.spacing(0, 4, 0, 1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        margin: theme.spacing(0, 1), // we no longer have horizontal items
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        maxWidth: 75
    },
}));

const StyledHorizontalMenu = styled.div({
    // Not be collapsed in an ugly way when the header is flooded with too many items:
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "auto",
});

const StyledMenuWrapper = styled.div(({ theme }) => ({
    // Take all available space, to make the header full-width and separate pull-left items from pull-right items:
    flexGrow: 0,

    // Not be collapsed in an ugly way when the header is flooded with too many items:
    flexShrink: 0,
    flexBasis: "auto",

    display: "flex",
    justifyContent: "flex-end",
    lineHeight: 1,

    // just margin
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginLeft: 0, // - only on small screen
    },
}));

const StyledSearchForm = styled(SearchForm)(({ theme }) => ({
    margin: 0,

    [theme.breakpoints.up("xs")]: {
        float: "right", // - only on large screen
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: "100%", // - only on small screen
    },
    "input": {
        width: "100%",
        // reset bootstrap/kms css (for use in legacy kms layouts)
        background: "none",
        border: "none",
        height: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(16),
        marginBottom: 0,
        color: "currentColor",
        "&:focus": {
            outline: "none",
            boxShadow: "none",
        },
    },
}));

const StyledUserMenu = styled(UserMenu)({
    margin: 0,
});

const SkipToContentLink = styled.a({
    flexGrow: 0,
    position: "absolute",
    top: -1000,
    left: -1000,
    height: 1,
    width: 1,
    textAlign: "left",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    "&:hover, &:active, &:focus": {
        left: 0,
        top: 0,
        width: "auto",
        height: "auto",
        overflow: "visible",
    },
});

const MenuTrigger = styled.div({
    flexGrow: 0,
});

const SearchIconButton = styled(IconButton)(({ theme }) => ({
    float: "right",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginLeft: theme.spacing(0),
    }
}));

const Spacer = styled("span")(({ theme }) => ({
    width: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        width: theme.spacing(1)
    }
}));

/**
 * Site Header Menu component (with navigation, user menu, locale menu..)
 */
export function HeaderMenu({
    className = "",
    sticky,
    headerColor,
    logo,
    userMenuItems,
    searchUrl,
    locales,
    currentLocale,
    showNav = true,
    scrollThreshold = 60,
    disableScrollLock = false,
    pages = [],
    topLinks,
    topLinksColor = "translucent",
    addNewItems,
    createAsLogin,
    menuStyle = MenuStyle.Vertical,
    badgesMenuItems,
}: HeaderMenuProps) {
    const theme = useTheme();
    const isTinyScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const mdScreen = useMediaQuery(theme.breakpoints.down("md"));
    const lgScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));

    const [showIcon, setShowIcon] = useState(true);
    const [showSearchForm, setShowSearchForm] = useState(!isTinyScreen);

    const handleMobileSearchBlur = () => {
        setShowIcon(true);
        setShowSearchForm(false);
    };

    const sendButtonAnalytics = useButtonAnalytics();

    const handleSubmitSearch = (value: string) => {
        sendButtonAnalytics("Search - Navbar", ButtonClickAnalyticsType.SEARCH);
        window.location.href = searchUrl + "=" + encodeURIComponent(value);
    };

    useEffect(() => {
        setShowSearchForm(!isTinyScreen);
        setShowIcon(true);
    }, [isTinyScreen]);

    // calculate top links, taking into account the menu max length
    const maxTopLinks = Math.min(
        topLinks ? topLinks.length : 0,
        xlScreen ? 6 : lgScreen ? 3 : 4
    );


    /*
     * The following factors are taken into consideration while deciding to render horizontal or vertical menu:
     * 1. Menu style from the props - the parent component could request to render vertical menu no matter what.
     * 2. Screen size - always render vertical menu on small screens.
     * 3. The amount of free space for the horizontal menu items: switch to vertical menu if less than 2 horizontal menu items could fit.
     *
     * `showVerticalMenu` variable handles factors 1 and 2 from above.
     * `isHorizontalMenuVisible` stores factor 3 from above.
     *
     * Note that the horizontal menu component is still being rendered even if the vertical menu should be displayed because of factor 3.
     * That's because it's the horizontal menu component who measures the number of the items that could fit the container,
     * and it should keep notifying us about the changes in this regard.
     */
    const showVerticalMenu = menuStyle === MenuStyle.Vertical || mdScreen;

    // Does horizontal menu have enough space to be visible?
    const [isHorizontalMenuVisible, setIsHorizontalMenuVisible] = useState(true);

    // Determine if the burger menu should be displayed
    const shouldDisplayMenu = showNav && (showVerticalMenu || !isHorizontalMenuVisible) && (pages?.length ?? 0) > 0;
    return (
        <HeaderMenuContainer
            sticky={sticky}
            scrollThreshold={scrollThreshold}
            headerColor={headerColor}
        >
            <SkipToContentLink
                href={"#contentWrap"}
                className={"skip-to-content-link"}
            >
                {translate("Skip to content")}
            </SkipToContentLink>
            {/* Vertical menu*/}
            {shouldDisplayMenu && (
                    <MenuTrigger className={`kms-ds-menu-trigger`}>
                        <SidebarMenu />
                    </MenuTrigger>
                )
            }
            {/* HeaderLogo is always visible on large screens, when search is closed on small screens */}
            {(!isTinyScreen || !(showSearchForm && searchUrl)) && (
                <StyledHeaderLogo
                    {...logo}
                    className={"kms-ds-header-menu-logo"}
                />
            )}

            {/* Horizontal menu (always show the container because it has the "flex-grow" style) */}
            <StyledHorizontalMenu className={"kms-ds-header-menu-horizontal"}>
                {showNav && !showVerticalMenu && (
                    <AutoHorizontalMenu
                        pages={pages}
                        openOnHover={true}
                        isTab={true}
                        onVisibilityChange={setIsHorizontalMenuVisible}
                    />
                )}
            </StyledHorizontalMenu>

            <StyledMenuWrapper className={"kms-ds-header-menu-search-wrap"}>
                {/* top links */}
                {topLinks && !mdScreen && (
                    <HorizontalMenu
                        pages={topLinks}
                        maxItems={maxTopLinks}
                        buttonVariant={"pill"}
                        buttonColor={topLinksColor}
                    />
                )}

                {(addNewItems || createAsLogin) &&
                    (!isTinyScreen || !(showSearchForm && searchUrl)) && (
                        <AddNewMenu
                            sections={addNewItems}
                            createAsLogin={createAsLogin}
                            disableScrollLock={disableScrollLock}
                        />
                    )}

                {(addNewItems || createAsLogin) && (showSearchForm || searchUrl) && (
                    <Spacer />
                )}

                {/* search form is always visible on large screens, only after button clicked on small screens */}
                {showSearchForm && !!searchUrl && (
                    <StyledSearchForm
                        onSubmit={handleSubmitSearch}
                        onInputChange={() => void 0}
                        showIcon={true}
                        placeholder={translate("Search")}
                        ariaLabel={translate("Enter text to search for media")}
                        onBlur={isTinyScreen ? handleMobileSearchBlur : noop}
                    />
                )}

                {/* show-search button is only visible on small screen */}
                {isTinyScreen && showIcon && !!searchUrl && (
                    <SearchIconButton
                        variant="borderless"
                        color="translucent"
                        onClick={() => {
                            setShowSearchForm(true);
                            setShowIcon(false);
                        }}
                        className={"kms-ds-header-menu-search-btn"}
                        aria-label={translate('Search all media')}
                    >
                        <Search24Icon />
                    </SearchIconButton>
                )}
            </StyledMenuWrapper>

            {badgesMenuItems &&
                (badgesMenuItems.earnedBadges.length > 0 ||
                    badgesMenuItems.badgesToEarn.length > 0) && (
                        <>
                            <Spacer />
                            <BadgesMenu badges={badgesMenuItems} />
                        </>
                )}

            {(userMenuItems || (locales && locales.length > 0 && currentLocale)) && (
                <Spacer />
            )}

            {userMenuItems && (
                <StyledUserMenu
                    className={"kms-ds-header-menu-user-menu"}
                    disableScrollLock={disableScrollLock}
                    items={userMenuItems}
                />
            )}
            {locales && locales.length > 0 && currentLocale && (
                <LocaleMenu
                    locales={locales}
                    disableScrollLock={disableScrollLock}
                    currentLocale={currentLocale}
                />
            )}

            {/*note: the button will be displayed only when the sidebar layout is active and the sidebar is closed*/}
            <SidebarOpenButton
                color={"translucent"}
                className={"kms-ds-header-menu-sidebar-button"}
                ariaLabel={translate("Open side bar")}
                onClick={() => sendButtonAnalytics("CnC - Header - Open sidebar", ButtonClickAnalyticsType.OPEN)}
            />
        </HeaderMenuContainer>
    );
}

export default HeaderMenu;
