import {StyledThumbContainer} from "./StyleComponents";
import {Typography} from "@kaltura/ds-react-components";

export interface ThumbnailProps {
    filesCount: number
}
export function Thumbnail({filesCount}: ThumbnailProps) {
    return (
        <StyledThumbContainer className={"kms-ds-upload-media-bulk-actions-row-thumbnail"}>
            <Typography variant={"body1Highlight"}>
                {filesCount}
            </Typography>
        </StyledThumbContainer>
    );
}

export default Thumbnail;
