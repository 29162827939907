import { useState } from "react";
import { KmsTypePublishUserCategories, KmsTypePublishCategories } from "@mediaspace/shared/types";
import { translate } from "@mediaspace/shared/utils";
import { PendingAlert } from "../pending-alert/PendingAlert";
import { CategorySearchInput } from "../category-search/CategorySearchInput";
import { GalleriesTabFormContainer } from "./GalleriesTabFormContainer";

export interface GalleriesTabProps {
    entryId: string;    
    galleries: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    onPendingClick: () => void;
}

/**
 * publish modal galleries tab
 */
export function GalleriesTab(props: GalleriesTabProps) {
    const { entryId, galleries, onPendingClick } = props;

    const [keyword, setKeyword] = useState<string>();

    const handleSearch = (keyword: string) => {
        setKeyword(keyword);
    };

    const handleClearSearch = () => {
        setKeyword("");
    };

    return (
        <>
            <PendingAlert entryId={entryId} galleries={galleries} onPendingClick={onPendingClick} />

            <CategorySearchInput
                placeholder={translate("Search galleries")}
                ariaLabel={translate("Enter text to search galleries")}
                onSearch={handleSearch}
                onResetSearch={handleClearSearch}
            />

            <GalleriesTabFormContainer {...props} keyword={keyword} />
        </>
    );
}
