import {StyledListItemContainer} from "./StyledComponents";
import {Checkbox} from "@kaltura/ds-react-components";

export interface ListItemProps {
    name: string;
    id: string;
    isAdded: boolean;
    onChange: (id: string, isChecked: boolean) => void;
}

/**
 * modal's playlists list item
 */
const ListItem = (props: ListItemProps) => {

    const {name, id, isAdded, onChange} = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(id, e.currentTarget.checked);
    }

    return (
        <StyledListItemContainer>
            <Checkbox
                onChange={handleChange}
                checked={isAdded}
                inputProps={{
                    'aria-label': name,
                }}
            />
            {name}
        </StyledListItemContainer>
    );
}

export default ListItem;
