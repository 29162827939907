import {
    generateUtilityClass,
    generateUtilityClasses,
} from "@mediaspace/shared/styled";

export interface ThumbnailClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the image element */
    image: string;
    /**  styles applied to the duration component */
    duration: string;
}

export function getThumbnailClass(slot: string) {
    return generateUtilityClass("kms-ds-thumbnail", slot);
}

export type ThumbnailClassKey = keyof ThumbnailClasses;

export const thumbnailClasses = generateUtilityClasses("kms-ds-thumbnail", [
    "root",
    "image",
    "duration",
]);
export default thumbnailClasses;
