import styled from "@emotion/styled";
import { Box } from "@mediaspace/shared/styled";
import { ReactNode } from "react";
import { VerticalTimeBar, VerticalTimeBarProps } from "./VerticalTimeBar";
import { fullWidthFlexItemStyles } from "@mediaspace/shared/utils";

/*
 * Box that contains vertical time bar from the left side and a custom content from the right side.
 */

export interface WithVerticalTimeBarProps extends VerticalTimeBarProps {
    children: ReactNode;
}

export const WithVerticalTimeBar = ({ className, start, end, children }: WithVerticalTimeBarProps) => (
    <StyledContainer className={className}>
        <StyledTimeBar start={start} end={end} />
        <StyledContents>{children}</StyledContents>
    </StyledContainer>
);

const StyledContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
}));

const StyledTimeBar = styled(VerticalTimeBar)({
    flexShrink: 0,
});

const StyledContents = styled(Box)(fullWidthFlexItemStyles);
