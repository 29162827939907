import styled from "@emotion/styled";
import React from "react";

export interface ScreenReaderLabelProps {
    text: string
}

const StyledScreenReaderLabel = styled.span`
    position: absolute !important;
    left: -9999px !important;
`;

/**
 * renders text that is only visible to screen-readers
 */
export function ScreenReaderLabel(props: ScreenReaderLabelProps) {
    return (
        <StyledScreenReaderLabel>
            {props.text}
        </StyledScreenReaderLabel>
    );
}

export default ScreenReaderLabel;
