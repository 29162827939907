import ReactDOM from "react-dom";
import React, { Suspense } from "react";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeProvider } from "@kaltura/ds-react-theme";
import { Config, ConfigProvider } from "@mediaspace/shared/data-kms-config";
import type { EditBoardContainerProps } from "@mediaspace/features/board-messages";

const EditBoardContainer = React.lazy(() => import("@mediaspace/features/board-messages"));

export const render = (
    id: string,
    props: EditBoardContainerProps & { context: Config }
) => {
    const { theming } = props.context;

    ReactDOM.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                {/*ds component inside no-ds page*/}
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode} scoped={true}>
                    <EditBoardContainer {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>,
        document.getElementById(id)
    );
};
