import {
    Button,
    Modal,
    ModalActions,
    modalClasses,
    ModalContent,
    ModalTitle
} from "@kaltura/ds-react-components";
import {baseUrl, postKmsData, translate} from "@mediaspace/shared/utils";
import React, {useState} from "react";
import PlaylistsFormContainer from "./playlists-form/PlaylistsFormContainer";
import {useToastsContext} from "@mediaspace/shared/ui";
import styled from "@emotion/styled";
import {useButtonAnalytics} from "@mediaspace/hooks";
import {ChangesType} from "@mediaspace/shared/media-actions/items/add-to-playlist/modal/AddToPlaylistModal";
import {ButtonClickAnalyticsType} from "@mediaspace/shared/types/ButtonClickAnalyticsType";

interface PlaylistsModalProps {
    isOpen: boolean;
    entryIds: string[];
    onClose: () => void;
}

export type SelectedPlaylists = {
    selected?: string[],
    extra?: string[],
    newPlaylists?: string[]
}

const StyledModal = styled(Modal)({
    [`& .${modalClasses.paper}`]: {
        minWidth: 443
    }
});

const PlaylistsModal = (props: PlaylistsModalProps) => {

    const {isOpen, entryIds, onClose} = props;

    const { showToast } = useToastsContext();

    const [processing, setProcessing] = useState(false);
    const [selectedPlaylists, setSelectedPlaylists] = useState({} as SelectedPlaylists);
    const [changes, setChanges] = useState<ChangesType>({});

    const sendButtonAnalytics = useButtonAnalytics();
    const onModalClose = () => {
        setSelectedPlaylists({} as SelectedPlaylists);
        sendButtonAnalytics("bulk actions - Add to playlist - Cancel", ButtonClickAnalyticsType.CLOSE);
        setChanges({});
        onClose();
    }

    const onSubmit = async () => {
        setProcessing(true);
        const url = `${baseUrl}/addtoplaylists/my-media/bulk-add-to-playlists`;
        const params = {
            entry: entryIds,
            selectedPlaylist: selectedPlaylists.selected,
            extraPlaylists: selectedPlaylists.extra,
            playlistsToCreate: selectedPlaylists.newPlaylists
        };

        try {
            const {success, messages, errors} = await postKmsData(url, params);
            if (success) {
                messages.forEach((message: string) => showToast({severity: "success", message}));
                sendButtonAnalytics("bulk actions - Add to playlist - Add", ButtonClickAnalyticsType.ADD);
            } else {
                errors.forEach((error: string) => showToast({severity: "error", message: error}));
            }
        } catch (e) {
            showToast({severity: "error", message: e.message});
        }

        setProcessing(false);
        onModalClose();
    };

    return (
        <StyledModal open={isOpen} maxWidth={false}>
            <ModalTitle>{translate("Add to playlist")}</ModalTitle>
            <ModalContent>
                {entryIds.length > 0 && (
                    <PlaylistsFormContainer
                        entryIds={entryIds}
                        setSelectedPlaylists={setSelectedPlaylists}
                        changes={changes}
                        setChanges={setChanges}
                    />
                )}
            </ModalContent>
            <ModalActions>
                <Button
                    variant={"borderless"}
                    onClick={onModalClose}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    loading={processing}
                    disabled={!Object.keys(changes).length}
                    onClick={onSubmit}
                >
                    {translate("Add")}
                </Button>
            </ModalActions>
        </StyledModal>
    )
}

export default PlaylistsModal;
