import {useState, useEffect, useMemo, cloneElement} from "react";
import { useConfig } from "@mediaspace/shared/data-kms-config";
import { kmsConnect, WrappedProps } from "@mediaspace/shared/kms-connect";
import { EventListProps } from "./EventList";
import { WebcastEntry } from "@mediaspace/shared/types";
import { AddToWatchlistHelper } from "@mediaspace/shared/utils";

interface EventListContainerProps extends EventListProps, WrappedProps {
    eventListComponent?: JSX.Element
}

/**
 * Container to render EventList and provide it with Add to Watchlist data.
 * we do it here and not in kms, to support page cache
 */
function EventListContainer(props: EventListContainerProps) {
	const { sessions = [], getFromKms, eventListComponent, hideFilterButton, ...rest } = props;
	const config = useConfig();

	// Prepare watchlist info
	const [watchlistIds, setWatchlistIds] = useState<string[]>(() =>
		config.application.isLoggedIn
			? AddToWatchlistHelper.getWatchlistList()
			: []
	);

	// check if there's at least 1 entry that can be added to my agenda and update local storage for it
	useEffect(() => {
		if (
			sessions.find((entry: WebcastEntry) => entry.canAddToWatchList) !== undefined
            && !!getFromKms
		) {
			AddToWatchlistHelper.updateWatchlistFromBE(
				getFromKms,
				config,
				(sessions: string[]) => {
					setWatchlistIds(sessions);
				}
			);
		}
	}, [getFromKms, config, sessions]);

	// set inWatchlist indication
	const currentSessions = useMemo(
		() =>
			sessions.map((session) => ({
				...session,
				inWatchlist: watchlistIds.includes(session.id),
			})),
		[sessions, watchlistIds]
	);


	const handleWatchLictToggle = (id: string, add: boolean) => {
		if(add) {
			setWatchlistIds((currentState) => [...currentState, id]);
		}
		else{
			setWatchlistIds((currentState) => currentState.filter(session => session !== id));
		}
	}

	return eventListComponent ?
        cloneElement(eventListComponent,{sessions: currentSessions, hideFilterButton, onToggleWatchList: handleWatchLictToggle ,...rest}) :
        null;

}

export default kmsConnect(EventListContainer);
