import styled from "@emotion/styled";
import { LabelledValue } from "@mediaspace/shared/types";
import { ChangeEvent, forwardRef, useCallback, useMemo } from "react";
import { Checkbox, Typography } from "@kaltura/ds-react-components";
import { FormLabel } from "./../form-label/FormLabel";

export interface MultiSelectHorizProps {
    id?: string,
    value: LabelledValue[],
    onChange: (value: LabelledValue[]) => void,
    onBlur?: () => void,
    error?: boolean,
    options: LabelledValue[],

    /**
     * field label, shown in fieldset legend.
     * the prop is optional. however, from a11y POV, if you do show
     * a label for the field, use this rather than an external label.
     */
    label?: string,
}

const StyledElementWrap = styled("li")(({ theme }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
}));
const StyledList = styled("ul")(({ theme }) => ({
    display: "flex",
    columnGap: theme.spacing(2),
    margin: 0,
    padding: 0,
}));

const StyledMultiSelect = styled("fieldset")(({ theme }) => ({
    paddingBlock: theme.spacing(1, 0),
    marginInline: "unset",
    paddingInline: "unset",
    border: "unset",
}));

const MultiSelectHoriz = forwardRef<HTMLFieldSetElement, MultiSelectHorizProps>(
    ({id, value, onChange, onBlur, error, options, label}: MultiSelectHorizProps, ref) => {

    /**
     * allow getting the changed option
     * @param option the option tied to the current checkbox
     * @return function that takes a change-event and triggers a unified change handler
     */
    const bindInputChanged = useCallback((option: LabelledValue) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                const updated = value.concat();
                updated.push(option);
                onChange(updated);
            }
            else {
                const updated = value.filter(opt => opt.value !== option.value)
                onChange(updated);
            }
        }
    }, [onChange, value]);

    const optionElements = useMemo(() => {
        return options.map(option => (
            <StyledElementWrap key={`${id}_${option.value}`}>
                <Checkbox title={option.label} id={`${id}_${option.value}`} onChange={bindInputChanged(option)} onBlur={onBlur} checked={value.findIndex(opt => opt.value === option.value) !== -1}/>
                <FormLabel text={option.label} htmlFor={`${id}_${option.value}`}/>
            </StyledElementWrap>
        ));
    }, [options, value])
    return (
        <StyledMultiSelect ref={ref}>
            {label && (<legend><Typography variant={"formLabel"}>{label}</Typography></legend>)}
            <StyledList>
                {optionElements}
            </StyledList>
        </StyledMultiSelect>
    );
});

export default MultiSelectHoriz;
