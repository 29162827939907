import { Alert } from "@kaltura/ds-react-components";
import { KmsTypePublishEntryCategories } from "@mediaspace/shared/types";
import { CategoryBubblesContainer } from "../category-bubbles/CategoryBubblesContainer";
import { translate } from "@mediaspace/shared/utils";
import { enrichAndCombineCategories, EnrichmentType } from "../../utils";
import styled from "@emotion/styled";
import { useContext } from "react";
import { ConfigContext } from "@mediaspace/shared/data-kms-config";
import { CategoriesList } from "../../media-categories/categories-list/CategoriesList";

export interface PublishedCategoriesProps {
    entryCategories: KmsTypePublishEntryCategories;
}

/**
 * published categories - media published categories (in the current instance)
 */
export function PublishedCategories({ entryCategories }: PublishedCategoriesProps) {
    const { channels = {}, galleries = {}, privateCategories } = entryCategories;
    const configContext = useContext(ConfigContext);
    const isKaf = configContext.application?.isKAF;
    const categoriesEnrichmentType = !isKaf ? EnrichmentType.all : EnrichmentType.label;

    // get the published categories
    const { published: publishedChannels = [] } = channels;
    const { published: publishedGalleries = [] } = galleries;
    const publishedLocations = enrichAndCombineCategories(publishedChannels, publishedGalleries, categoriesEnrichmentType);

    // get the pending categories
    const { pending: pendingChannels = [] } = channels;
    const { pending: pendingGalleries = [] } = galleries;
    const pendingLocations = enrichAndCombineCategories(pendingChannels, pendingGalleries, categoriesEnrichmentType);

    // get the rejected categories
    const { rejected: rejectedChannels = [] } = channels;
    const { rejected: rejectedGalleries = [] } = galleries;
    const rejectedLocations = enrichAndCombineCategories(rejectedChannels, rejectedGalleries, categoriesEnrichmentType);


    return (
        <StyledContainer>
            {privateCategories && (
                <StyledAlert severity="warning">{translate("Media is published to a private location")}</StyledAlert>
            )}

            {!isKaf ? (
                <CategoryBubblesContainer
                    categories={publishedLocations}
                    isCollapsable={false}
                    className="kms-ds-publish-media-categories-published"
                />
            ) : (
                <CategoriesList className="kms-ds-publish-media-categories-published" categories={publishedLocations} />
            )}

            <>
                <CategoriesList
                    className="kms-ds-publish-media-categories-pending"
                    categories={pendingLocations}
                    title={{ label: translate("Pending in:"), color: "warning.main" }}
                />

                <CategoriesList
                    className="kms-ds-publish-media-categories-rejected"
                    categories={rejectedLocations}
                    title={{ label: translate("Rejected in:"), color: "error.main" }}
                />
            </>
        </StyledContainer>
    );
}

const StyledContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));
