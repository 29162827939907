import {
    generateUtilityClass,
    generateUtilityClasses,
} from "@mediaspace/shared/styled";

export function getFooterClass(slot: string) {
    return generateUtilityClass("kms-ds-footer", slot);
}

export interface FooterClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the image element */
    image: string;
    /**  styles applied to the logo element */
    logo: string;
    /**  styles applied to the promotion wrapper element */
    promotion: string;
    /**  styles applied to the promotion title element */
    title: string;
    /**  styles applied to the promotion text element */
    text: string;
    /**  styles applied to the promotion button element */
    button: string;
    /**  styles applied to the bottom text element */
    bottomText: string;
    /**  styles applied to the link element */
    link: string;
    /**  styles applied to the social icon element */
    socialIcon: string;
    /**  styles applied to the social links wrapper element */
    socialLinks: string;
    /**  styles applied to the content element */
    content: string;
    /**  styles applied to the bottom container element */
    bottomContainer: string;
    /**  styles applied to the bottom content element */
    bottomContent: string;
    /**  styles applied to the texts and links wrapper element */
    textAndLinks: string;
    /**  styles applied to the texts and links separator element */
    separator: string;
    /**  styles applied to the links wrapper element */
    linkWrapper: string;
}

export type FooterClassKey = keyof FooterClasses;

export const footerClasses = generateUtilityClasses("kms-ds-footer", [
    "root",
    "image",
    "logo",
    "promotion",
    "title",
    "text",
    "button",
    "bottomText",
    "link",
    "socialIcon",
    "socialLinks",
    "content",
    "bottomContainer",
    "bottomContent",
    "textAndLinks",
    "separator",
    "linkWrapper",
]);

export default footerClasses;
