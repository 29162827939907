import {FileMetaData, UploadStatus} from "@mediaspace/shared/upload";
import {useCallback} from "react";
import {translate} from "@mediaspace/shared/utils";
import {StyledNameAndStatus, StyledTypography} from "./StyleComponents";

export interface NameAndStatusProps {
    name: string;
    metadata: FileMetaData;
}


export function NameAndStatus({name, metadata}: NameAndStatusProps) {
    const getFileStatus = useCallback(metadata => {
        switch (metadata.status) {
            case UploadStatus.PRE_QUEUE:
            case UploadStatus.IN_QUEUE:
                return translate("In Queue");

            case UploadStatus.UPLOADING:
                return translate("Uploading %1 %", [metadata.progress || 0]);

            case UploadStatus.PROCESSING:
                return translate("Processing");

            case UploadStatus.READY:
                return translate("Ready");

            case UploadStatus.ENTRY_FAILED:
                return translate("Processing Failed");

            case UploadStatus.UPLOAD_FAILED:
                return translate("Upload Failed");

            case UploadStatus.CANCELED:
                return translate("Canceled");
        }
        // we are not expected to get here.
        return "";
    }, []) ;

    return (
        <StyledNameAndStatus className={"kms-ds-upload-media-file-row-name-status"}>
            <StyledTypography variant={"body1Highlight"}>{name}</StyledTypography>
            <StyledTypography variant={"body1"}>{getFileStatus(metadata)}</StyledTypography>
        </StyledNameAndStatus>
    );
}

export default NameAndStatus;
