import {
    generateUtilityClass,
    generateUtilityClasses,
} from "@mediaspace/shared/styled";

export function getPopperClass(slot: string) {
    return generateUtilityClass("kms-ds-popper", slot);
}

export interface PopperClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the arrow element */
    arrow: string;
}
export type PopperClassKey = keyof PopperClasses;

export const popperClasses = generateUtilityClasses("kms-ds-popper", [
    "root",
    "arrow",
]);

export default popperClasses;
