import { useMemo } from "react";
import { useScheduledEvent } from "@mediaspace/hooks";
import { CncProps } from "./CncProps";

/**
 * Post-processing C&C widget configs on the fly based on dynamic and frontend-only parameters:
 * - Scheduling, according to the current time (e.g. enable the group chat according to the time window)
 * - Values that are passed for a cached page via global variables (e.g. the JWT)
 * - Cookie consent.
 */
export const useProcessedCncConfig = ({
    config,
    isScheduledModeration,
    moderationStartTime,
    moderationEndTime,
    isScheduledGroupChat,
    groupChatStartTime,
    groupChatEndTime,
}: CncProps) => {
    const isPastModerationStartTime = useScheduledEvent(moderationStartTime);
    const isPastModerationEndTime = useScheduledEvent(moderationEndTime);
    const isScheduledModerationActive = isPastModerationStartTime && !isPastModerationEndTime;

    const isPastGroupChatStartTime = useScheduledEvent(groupChatStartTime);
    const isPastGroupChatEndTime = useScheduledEvent(groupChatEndTime);
    const isScheduledGroupChatActive = isPastGroupChatStartTime && !isPastGroupChatEndTime;

    const jwtToken = useMemo(() => (window as any).KMS_GLOBAL?.cncJwt, []);

    const cookieConsent = useMemo(() => (window as any).GLOBAL_KMS?.shortbread?.getConsentCookie?.()?.functional, []);

    return useMemo(() => {
        let result = config;

        if (isScheduledModeration) {
            // We are on scheduled entry page and entry moderation is configured to According to schedule
            result = {
                ...result,
                moderation: {
                    ...result.moderation,
                    ui: {
                        ...result.moderation?.ui,
                        ctaButton: {
                            ...result.moderation?.ui?.ctaButton,
                            type: isScheduledModerationActive
                                ? "chatWithExpert"
                                : null,
                        },
                    },
                },
            };
        }

        if (isScheduledGroupChat) {
            // We are on scheduled entry page and group chat is configured to "According to schedule"
            result = {
                ...result,
                ui: {
                    ...result.ui,
                    groupChat: {
                        ...result.ui?.groupChat,
                        enabled: isScheduledGroupChatActive,
                    },
                },
            };
        }

        if (jwtToken) {
            result = {
                ...result,
                server: {
                    ...result.server,
                    jwtToken,
                },
            };
        }

        if (cookieConsent) {
            result = {
                ...result,
                consent: {
                    ...result.consent,
                    cookieConsent,
                },
            };
        }

        return result;
    }, [
        config,
        isScheduledModeration,
        isScheduledModerationActive,
        isScheduledGroupChat,
        isScheduledGroupChatActive,
        jwtToken,
        cookieConsent,
    ]);
};
