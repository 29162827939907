import { BulkActionProps } from "./../BulkActionProps";
import {
    SetCollaborationContainer,
    SetCollaborationContainerProps
} from "@mediaspace/shared/media-actions/items/collaboration/set-collaboration";


export interface BulkCollaborationProps extends BulkActionProps, SetCollaborationContainerProps { }


/**
 * wrapper for showing the collaboration-setter component as my-media bulk action
 */
export function BulkCollaboration({ reloadMedia, id, ...rest }: BulkCollaborationProps) {

    return (
        <SetCollaborationContainer {...rest}
                                   onCollaboratorsSet={reloadMedia}
                                   analyticsEventNameBase={" bulk actions"}/>
    );
}
