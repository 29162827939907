import {Button, MenuItem} from "@kaltura/ds-react-components";
import {Playlist24Icon} from "@kaltura/ds-react-icons";
import {translate} from "@mediaspace/shared/utils";
import React, {useState} from "react";
import PlaylistsModal from "./playlists-modal/PlaylistsModal";
import {BulkActionProps} from "../../bulk-actions/BulkActionProps";

export interface BulkAddToPlaylistProps extends BulkActionProps {
    /**
     * show as button or as menu item
     * (set by server interface)
     */
    displayMode: "button" | "menu";
}

const BulkAddToPlaylist = (props: BulkAddToPlaylistProps) => {

    const {displayMode, entryIds, onCloseMenu} = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClick = () => {
        setIsOpen(true);
    }

    const handleModalClose = () => {
        setIsOpen(false);
        if (displayMode === "menu") {
            onCloseMenu();
        }
    }

    return (
        <>
            {displayMode === "button" && (
                <Button variant={"borderless"} onClick={handleClick} startIcon={<Playlist24Icon />}>{translate("Add to playlist")}</Button>
            )}
            {displayMode === "menu" && (
                <MenuItem onClick={handleClick}>{translate("Add to playlist")}</MenuItem>
            )}

            <PlaylistsModal
                isOpen={isOpen}
                entryIds={entryIds}
                onClose={handleModalClose}
            />
        </>
    );
}

export default BulkAddToPlaylist;
