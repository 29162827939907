import React, { useEffect } from "react";
import { Config } from "./Config";
import { DateType, PlaylistThumbLinkType } from "@mediaspace/shared/types";
import { SetLocalCodeHelper } from "@mediaspace/shared/utils";

export const defaultContext: Config = {
    application: {
        isLoggedIn: false,
        isKAF: false,
        enableLoadButton: false,
        dateType: DateType.TIME_AGO,
        enableEntryTitles: false,
        timeDisplay: "am-pm",
        currentLocaleCode: "en",
        shortDateFormat: "MM/DD/YYYY",
        dateFormats: {
            longDate: "dddd, MMMM DD, YYYY",
            shortDate: "MMMM DD, YYYY",
            longDateNoYear: "dddd, MMMM DD",
        },
        playlistThumbLink: PlaylistThumbLinkType.playlistPage
    },
    calendar: {
        getAppointmentUrl: "/default/calendar/get-calendar-appointment",
    },
    watchlist: {
        localStorageExpiry: 5,
    },
    passwordValidations: {
        noRules: false,
        validations: {
            rule1: {
                regex: "^.{8,14}$",
                description: "Must be of length between 8 and 14"
            },
            rule2: {
                regex: "[0-9]+",
                description: "Must contain at least one digit (0-9)"
            },
            rule3: {
                regex: "[a-z]+",
                description: "Must contain at least one lowercase letter (a-z)"
            },
            rule4: {
                regex: "[~!@#$%^*=+?}\\(\\)\\-\\[\\]\\{\\}]+",
                description: "Must contain at least one of the following symbols:  %%~!@#$^*=+?[]{}"
            },
            rule5: {
                regex: "^[^<>]*$",
                description: "Must not contain the following characters: < or >"
            }
        }
    },
    siteRegistration: {
        editor: {
            fieldTypeOptions: []
        }
    },
    dsLayout: {
        components: {},
        headerColor: "dark",
    },
    eventPlatform: {
        eventListItem: {
            showThumbnail: false
        }
    },
    analytics: {
        analyticsContext: "",
    },
    categories: {
        rootGalleryCategoryId: '13744992'
    }
};

export const ConfigContext: React.Context<Config> =
    React.createContext(defaultContext);

export interface ConfigProviderProps {
    context?: Config;
}

/**
 * This component makes the `config` available down the React tree.
 * It should preferably be used at **the root of your component tree**.
 */
export const ConfigProvider: React.FC<ConfigProviderProps> = (props) => {
    const { children, context: config } = props;

    const currentLocaleCode = config?.application.currentLocaleCode;
    useEffect(() => SetLocalCodeHelper.setLocalLanguage(currentLocaleCode), [currentLocaleCode]);

    return (
        <ConfigContext.Provider
            value={
                typeof config === "object"
                    ? { ...defaultContext, ...config }
                    : defaultContext
            }
        >
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;
