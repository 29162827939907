import {ReactNode} from "react";

interface TabPanelProps {
    children?: ReactNode;
    name: string;
    currentTab: string;
}

/**
 * component to represent a tab-panel in the personal profile page,
 * wired with accessibility props etc.
 */
function TabPanel({ children, currentTab, name }: TabPanelProps) {

    return (
        <div
            role="tabpanel"
            hidden={currentTab !== name}
            id={`tabpanel-${name}`}
            aria-labelledby={`tab-${name}`}
        >
            {children}
        </div>
    );
}

export default TabPanel;
