import React from "react";
import { MediaPlayerV3, MediaPlayerV3Props } from "../playerV3/MediaPlayerV3";

export type PlayerProps = MediaPlayerV3Props;

/**
 *  Player factory - renders a V2(not implemented yet) or V7 player according to the relevant config.
 */
export function Player(props: PlayerProps) {
    const { media, config, onError, onReady, onMediaLoaded, className } = props;

    return (
        <MediaPlayerV3
            media={media}
            config={config}
            onError={onError}
            onReady={onReady}
            onMediaLoaded={onMediaLoaded}
            className={className}
        />
    );
}
