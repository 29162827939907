import React, {
    SyntheticEvent,
    ReactElement,
    RefObject,
    useState,
} from "react";
import copy from "copy-to-clipboard";
import { Tooltip } from "@kaltura/ds-react-components";

interface Props {
    text: string;
    toolTipLabel: string;
    children: ReactElement<any>;
}

/**
 * Copy Button Component -  triggers a copy to clipboard functionality.
 *
 * Can work alone, or with CopiedText Component, connected via the ref prop.
 */
const CopyButton = React.forwardRef( (props: Props, ref: RefObject<HTMLTextAreaElement>) => {
    const { text, toolTipLabel, children, ...passThroughProps } = props;
    const [copied, setCopied] = useState(false);

    const copyText = (event: SyntheticEvent<HTMLButtonElement>) => {
        // copy the text to clipboard
        copy(text);

        // mark state as copied - for the tooltip
        setCopied(true);

        setTimeout(function () {
            setCopied(false);
        }, 2000);

        // given a text ref - focus and select it
        if (!ref || !ref.current) {
            return;
        }
        const target = ref.current;
        target.focus();
        target.select();
    };

    const elem = React.Children.only(children);

    return (
        <>
            <Tooltip
                title={toolTipLabel}
                placement={'top'}
                open={!!copied}
            >
                {React.cloneElement(elem, {
                    ...passThroughProps,
                    onClick: copyText,
                })}
            </Tooltip>
        </>
    );
});

export default CopyButton;
