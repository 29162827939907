import React, { useRef, useState } from "react";
import {
    IconButton,
    BaseInput as Input,
    BaseInputProps as InputProps, baseInputClasses
} from "@kaltura/ds-react-components";
import { Clear24Icon, Search24Icon } from "@kaltura/ds-react-icons";
import ScreenReaderLabel from "../screen-reader-label/ScreenReaderLabel";
import styled from "@emotion/styled";
import { BaseInputClasses as InputClasses } from "@kaltura/ds-react-components";
import { translate } from "@mediaspace/shared/utils";

export interface SearchInputProps extends Pick<InputProps, "color"> {
    placeholder?: string;
    ariaLabel?: string;
    showIcon?: boolean;
    disabled?: boolean;
    inputClasses?: Partial<InputClasses>;
    className?: string;
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    onReset?: () => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
 }

// override kms css
const StyledInput = styled(Input)(({ theme }) => ({
    [`form:not(.searchForm):not(.navbar-search) & input[type='text']:not(.tags-input-field)`]:
        {
            border: "none",
            paddingLeft: theme.spacing(1),
        },
    [`&.${baseInputClasses.adornedStart}, &.${baseInputClasses.adornedEnd}`]: {
        "svg": {
            color: theme.kaltura.palette.tone3
        },
    },
}));

const ClearButton = styled(IconButton)({
    border: "none",
    height: 30,
    "&:hover, &:active" : {
        height: 30
    },
    "&:hover" : {
        backgroundColor: "initial"
    }
});

/**
 * search input for Event Platform - the DS way
 * Consider using the wrapper component SearchForm instead of this one directly.
 */
export function SearchInput(props: SearchInputProps) {
    const {
        placeholder = "",
        ariaLabel,
        showIcon = false,
        disabled = false,
        className = "",
        inputClasses,
        onInputChange,
        onBlur,
        onReset,
        color = "translucent",
        onKeyDown,
    } = props;

    const [value, setValue] = useState("");
    const fieldRef = useRef<HTMLInputElement>(null);

    const handleInputBlur = () => {
        if (onBlur) {
            onBlur();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onInputChange && onInputChange(event);
    };

    const handleReset = () => {
        if (fieldRef && fieldRef.current) {
            fieldRef.current.value = "";
            setValue("");
        }
        if (onReset) {
            onReset();
        }
    };

    return (
        <div className={`kms-ds-search-input ${className}`}>
            <StyledInput
                classes={inputClasses}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleInputBlur}
                disabled={disabled}
                inputRef={fieldRef}
                fullWidth={true}
                color={color}
                startAdornment={showIcon && <Search24Icon />}
                endAdornment={
                    value.length > 0 && (
                        <ClearButton
                            className="kms-ds-search-clear-button"
                            onClick={handleReset}
                            onMouseUp={handleReset}
                            color={color}
                        >
                            <Clear24Icon />
                            <ScreenReaderLabel
                                text={translate("Clear Search")}
                            />
                        </ClearButton>
                    )
                }
                inputProps={{
                    "aria-label":ariaLabel
                }}
                onKeyDown={onKeyDown}
            />
        </div>
    );
}

export default SearchInput;
